import styled from 'styled-components';
export const StyleContent = {
    ContentBox: styled.div `
    display: flex;
    justify-content: center;
    width: 100%;
    max-width: 1260px;
    margin: 0 auto;
    gap: 10px;
    ${({ $flexDirection }) => $flexDirection};

    @media (max-width: 768px) {
      flex-direction: column;
      align-items: center;
    };
  `,
    ContentBoxLeft: styled.div `
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    max-width: 513px;

    h3 {
      color: #070708;
      font-family: "Public Sans";
      font-size: 32px;
      font-style: normal;
      font-weight: 600;
      line-height: 140%; /* 44.8px */
      margin-bottom: 16px;
    }

    p {
      color: #070708;
      font-family: "Public Sans";
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 140%; /* 25.2px */
      margin-bottom: 30px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    
    @media (max-width: 768px) {
      align-items: center;
      max-width: 100%;
      margin-bottom: 20px;
    };
  `,
    ContentBoxRight: styled.div `
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 23px;
    width: 100%;
    div {
      img {
        object-fit: contain;
        width: 100%;
        max-width: 192px;
      }
    }
    /* @media (max-width: 535px) {
      flex-direction: column;
    }; */
  `,
    ContentBoxWrap: styled.div `
    margin: 140px 10px 0;
    div {
      div {
        div {
          max-width: max-content;
          width: 100%;
          height: 100%;
          img {
            object-fit: contain;
            width: 100%;
            height: 100%;

            &:nth-child(1) {
              max-width: 251px;
            }

            &:nth-child(2) {
              max-width: 309px;
              height: 100%;
            }
          }
        }
      }
    }
    /* @media (max-width: 535px) {
      flex-direction: column;
    }; */
  `,
};
