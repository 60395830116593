export var ERoutesLink;
(function (ERoutesLink) {
    ERoutesLink["HOME"] = "/";
    ERoutesLink["ADVANCED_ENCRYPTION"] = "/advanced-encryption";
    ERoutesLink["PERSONAL_ACCOUNTS"] = "/personal-accounts";
    ERoutesLink["BUSINESS_ACCOUNTS"] = "/business-accounts";
    ERoutesLink["AI_BOT"] = "/ai-bot";
    ERoutesLink["ELLO_PAY"] = "/ello-pay";
    ERoutesLink["FREE_PAY"] = "/free-pay";
    ERoutesLink["GROUPS"] = "/groups";
})(ERoutesLink || (ERoutesLink = {}));
