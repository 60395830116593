import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { navList } from '../../CONST/nav-list';
import { S } from './AdvancedBlockStyles';
import { CylinderButton } from '../CylinderButton/CylinderButton';
import { TextContainer } from '../TextContainer/TextContainer';
import { Diagram } from './Diagram/Diagram';
import { ButtonsContainer } from '../ButtonsContainer/ButtonsContainer';
import { ERoutesLink } from '../../CONST/routes-link';
const title = 'Advanced encryption with MTProto 2.0 technology';
const text = `Ello uses encryption for all messages and calls by default.
This means messages cannot be read or eavesdropped by anyone.`;
export const AdvancedBlock = () => (_jsxs(S.AdvancedWrap, { style: { marginTop: '20px' }, children: [_jsxs(CylinderButton, { background: "primary", color: "--Color-Text-White", children: [_jsxs("svg", { xmlns: "http://www.w3.org/2000/svg", width: "18", height: "18", viewBox: "0 0 18 18", fill: "none", children: [_jsx("path", { d: "M12.75 15.375H5.25C3 15.375 1.5 14.25 1.5 11.625V6.375C1.5 3.75 3 2.625 5.25 2.625H12.75C15 2.625 16.5 3.75 16.5 6.375V11.625C16.5 14.25 15 15.375 12.75 15.375Z", stroke: "white", strokeMiterlimit: "10", strokeLinecap: "round", strokeLinejoin: "round" }), _jsx("path", { d: "M12.75 6.75L10.4025 8.625C9.63 9.24 8.3625 9.24 7.59 8.625L5.25 6.75", stroke: "white", strokeMiterlimit: "10", strokeLinecap: "round", strokeLinejoin: "round" })] }), _jsx("span", { children: "Messaging Security" })] }), _jsxs(S.TextWrap, { children: [_jsx(TextContainer, { title: title, paragraph: text }), _jsx(ButtonsContainer, { btnLeftName: "Get App", btnRightName: "Learn More", link: ERoutesLink.ADVANCED_ENCRYPTION, children: _jsxs("svg", { xmlns: "http://www.w3.org/2000/svg", width: "18", height: "19", viewBox: "0 0 18 19", fill: "none", children: [_jsx("path", { d: "M10.8225 4.94727L15.375 9.49977L10.8225 14.0523", stroke: "#0A49A5", strokeWidth: "1.5", strokeMiterlimit: "10", strokeLinecap: "round", strokeLinejoin: "round" }), _jsx("path", { d: "M2.625 9.5H15.2475", stroke: "#0A49A5", strokeWidth: "1.5", strokeMiterlimit: "10", strokeLinecap: "round", strokeLinejoin: "round" })] }) })] }), _jsx(Diagram, {}), _jsx("div", { id: navList[0].toLowerCase() })] }));
