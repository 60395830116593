import styled, { keyframes, css } from 'styled-components';
const arrowAnimation = keyframes `
  0% {
    transform: scale(0);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
`;
export const S = {
    DiagramWrapper: styled.div `
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    @media (max-width: 1400px) {
      width: max-content;
      margin: 0 auto;
      grid-template-columns: auto auto auto;
    }
  `,
    BlockWrap: styled.div `
    position: relative;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;

    @media (max-width: 1400px) {
      width: max-content;
    }
  `,
    BlockWrapCenter: styled.div `
    padding: 10px;
    margin: 0 10px;
    border-radius: 55px;
    border: 1px dashed #0C5ACC;
    background: rgba(12, 90, 204, 0.03);

    @media (max-width: 1400px) {
      border-radius: 27.5px;
    }
  `,
    Block: styled.div `
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh; /* Наприклад, на всю висоту вікна */
  max-width: 200px;
  max-height: 200px;
  text-align: center;
  border-radius: 50px;
  border: 1px solid var(--Color-Blue-400, #89B6F8);
  background: radial-gradient(50% 50% at 50% 50%, #F3F7FE 50%, #EDF3FE 100%);
  opacity: 0;
  transform: scale(0);
  transition: opacity 0.5s, transform 0.5s;
  ${({ $visible, $time }) => $visible
        && css `
      animation: ${arrowAnimation} ${$time}s ease-out forwards;
    `};

  @media (max-width: 1400px) {
    width: 50vw;
    height: 50vh;
    max-width: 150px;
    max-height: 150px;
    border-radius: 25px;
  }

  @media (max-width: 1023px) {
    font-size: 12px;
    line-height: 18px;
    max-width: 100px;
    max-height: 100px;
    padding: 8px;
  }
  `,
    BlockEmpty: styled.div `
    position: relative;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    aspect-ratio: 1 / 1; /* Забезпечує однакове співвідношення сторін */
    width: 100vw;
    height: 100vh; /* Наприклад, на всю висоту вікна */
    max-width: 200px;
    max-height: 200px;
    opacity: 0;
    transform: scale(0);
    transition: opacity 0.5s, transform 0.5s;
    ${({ $visible, $time }) => $visible
        && css `
        animation: ${arrowAnimation} ${$time}s ease-out forwards;
      `};

    @media (max-width: 1400px) {
      width: 50vw;
      height: 50vh; /* Наприклад, на всю висоту вікна */
      max-width: 150px;
      max-height: 150px;
    }

    @media (max-width: 1023px) {
      max-width: 100px;
      max-height: 100px;
    }
  `,
    CA: styled.div `
    position: absolute;
    top: calc(50% - 12px);
    left: calc(50% - 16px);

    color: var(--Color-Bg-Blue, #0A49A5);
    text-align: center;
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px; /* 150% */

    @media (max-width: 1024px) {
      font-size: 12px;
      line-height: 18px; /* 150% */
      top: calc(50% - 8px);
      left: calc(50% - 12px);
    }
  `,
    ArrowVertical: styled.div `
    position: absolute;
    z-index: 10px;
    background-color: #0A49A5;
    width: 4px;
    height: 20px;
    top: -20px;

    &::before {
      content: '';  // Required for pseudo-elements
      position: absolute;
      bottom: 100%;  // Position the triangle just above the bar
      left: 50%;
      transform: translateX(-50%);
      border-left: 7px solid transparent;
      border-right: 7px solid transparent;
      border-bottom: 9px solid #0A49A5;  // This defines the color of the triangle
      width: 0;
      height: 0;

      @media (max-width: 1023px) {
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        border-bottom: 7px solid #0A49A5;
        bottom: 100%;  // Position the triangle just above the bar
        left: 50%;  // Position the triangle just above the bar
      }
    }

    &::after {
      content: '';  // Required for pseudo-elements
      display: block;  // Ensure the after element is rendered
      border-radius: 50%;
      background-color: #0A49A5;
      width: 10px;
      height: 10px;
      position: absolute;  // Add position if needed
      bottom: -5px;
      left: 50%;
      transform: translateX(-50%);

      @media (max-width: 1023px) {
        width: 7px;
        height: 7px;
        bottom: -3px;
        left: 50%;
      }
    }

    @media (max-width: 1023px) {
      width: 2px;
    }
  `,
    ArrowHorizontal: styled.div `
    position: absolute;
    z-index: 10px;
    background-color: #0A49A5;
    width: 20px;
    height: 4px;
    right: -20px;

    &::before {
      content: '';  // Required for pseudo-elements
      position: absolute;
      z-index: 1;
      right: -12px;  // Position the triangle just above the bar
      top: calc(50% - 7px);
      transform: translate(-50%);
      border-top: 7px solid transparent;
      border-bottom: 7px solid transparent;
      border-left: 9px solid #0A49A5;  // This defines the color of the triangle
      width: 0;
      height: 0;

      @media (max-width: 1023px) {
        border-top: 5px solid transparent;
        border-bottom: 5px solid transparent;
        border-left: 7px solid #0A49A5;
        top: calc(50% - 5px);
        right: -9px;  // Position the triangle just above the bar
      }
    }

    &::after {
      content: '';  // Required for pseudo-elements
      display: block;  // Ensure the after element is rendered
      border-radius: 50%;
      background-color: #0A49A5;
      width: 10px;
      height: 10px;
      position: absolute;  // Add position if needed
      left: 0;
      top: calc(50% - 5px);
      transform: translateX(-50%);

      @media (max-width: 1023px) {
        width: 7px;
        height: 7px;
        top: calc(50% - 3px);
      }
    }

    @media (max-width: 1023px) {
      height: 2px;
    }
  `,
    EmptyArrow: styled.div `
    position: relative;
    right: -20px;
    height: 4px;
    width: calc(50% + 20px);
    background-color: #0A49A5;

    &::before {
      content: '';  // Required for pseudo-elements
      position: absolute;
      z-index: 1;
      right: -12px;  // Position the triangle just above the bar
      top: calc(50% - 7px);
      transform: translate(-50%);
      border-top: 7px solid transparent;
      border-bottom: 7px solid transparent;
      border-left: 9px solid #0A49A5;  // This defines the color of the triangle
      width: 0;
      height: 0;

      @media (max-width: 1023px) {
        border-top: 5px solid transparent;
        border-bottom: 5px solid transparent;
        border-left: 7px solid #0A49A5;

        right: -9px;  // Position the triangle just above the bar
        top: calc(50% - 5px);
      }
    }

    @media (max-width: 1023px) {
      height: 2px;
    }
  `,
    EmptyLine: styled.div `
    position: absolute;
    top: -20px;
    left: 50%;
    height: calc(50% + 20px);
    width: 4px;
    background-color: #0A49A5;

    &::after {
      content: '';  // Required for pseudo-elements
      display: block;  // Ensure the after element is rendered
      border-radius: 50%;
      background-color: #0A49A5;
      width: 10px;
      height: 10px;
      position: absolute;  // Add position if needed
      left: 2px;
      top: -5px;
      transform: translateX(-50%);

      @media (max-width: 1023px) {
        width: 7px;
        height: 7px;
        left: 1px;
      }
    }

    @media (max-width: 1023px) {
      width: 2px;
    }
  `,
};
