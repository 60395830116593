// Light
export const themeLight = {
    colors: {
        primary: '#0070f3',
        secondary: '#ff4081',
        background: '#f4f4f4',
        text: '#333',
        '--Color-Border-Blue': '#0A49A5',
        '--Color-Text-White': '#FFF',
        '--Color-Text-Dark': '#070708',
        header_background: '#edf3fe',
        '--dark-blue-gray': '#2c3e50',
        burgerMenu: '#e3eefe',
        '--Dark-Charcoal': '#2F2F2F',
        transparent: 'transparent',
        '--Color-Purple': '#9B51E0',
        '--Color-Orange': '#F90',
        '--Color-background-blue': '#5179E0',
        '--Color-Dark-Green': '#27AE60',
        '--Color-Red': '#EF4062',
    },
    fonts: {
        main: "'Arial', sans-serif",
        code: "'Courier New', monospace",
    },
    spacing: {
        small: '8px',
        medium: '16px',
        large: '24px',
    },
};
// themeDark
export const themeDark = {
    colors: {
        primary: '#0070f3',
        secondary: '#f081',
        background: '#f4f4',
        text: '#003',
        '--Color-Border-Blue': '#0A49A5',
        '--Color-Border-Blue-2': '#0C5ACC',
        '--Color-Text-White': '#FFF',
        '--Color-Text-Dark': '#070708',
        header_background: '#edf3fe',
        burgerMenu: '#e3eefe',
        '--Dark-Charcoal': '#2F2F2F',
        transparent: 'transparent',
        '--Color-Purple': '#9B51E0',
        '--Color-Orange': '#F90',
        '--Color-background-blue': '#5179E0',
        '--Color-Dark-Green': '#27AE60',
        '--Color-Red': '#EF4062',
    },
    fonts: {
        main: "'Arial', sans-serif",
        code: "'Courier New', monospace",
    },
    spacing: {
        small: '8px',
        medium: '16px',
        large: '24px',
    },
};
