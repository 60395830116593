import styled from 'styled-components';
export const S = {
    CylinderButton: styled.button `
    display: flex;
    padding: 8px 16px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 140px;
    border: none;
    color: ${(props) => props.theme.colors[props.$color]};
    background-color: ${(props) => props.theme.colors[props.$background]};
    margin: 0 auto;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 140%;
    transition: transform 0.3s ease;

    &:hover {
      transform: scale(1.1); /* Збільшує кнопку на 10% */
    }

    &:active {
      transform: scale(1.05); /* Невелике зменшення при натисканні */
    }
  `,
};
