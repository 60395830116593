import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Routes, Route } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import { ParallaxProvider } from 'react-scroll-parallax';
import { Header } from '../components/Header/Header';
import { themeDark } from '../assets/styles/ThemeProvider';
import { GlobalStyle } from '../assets/styles/GlobalStyle';
import HomePage from '../pages/HomePage/HomePage';
import NotFoundPage from '../pages/NotFoundPage/NotFoundPage';
import { S } from './AppStyles';
import { Footer } from '../components/Footer/Footer';
import AdvancedEncryption from '../pages/AdvancedEncryption/AdvancedEncryption';
import { ERoutesLink } from '../CONST/routes-link';
import { PersonalAccounts } from '../pages/PersonalAccounts/PersonalAccounts';
import { BusinessAccounts } from '../pages/BusinessAccounts/BusinessAccounts';
import { AiBotDetails } from '../pages/AiBotDetails/AiBotDetails';
import { FreePaid } from '../pages/FreePaid/FreePaid';
import AlloPayPage from '../pages/AlloPayPage/AlloPayPage';
import { Groups } from '../pages/Groups/Groups';
export const App = () => (_jsxs(ThemeProvider, { theme: themeDark, children: [_jsx(GlobalStyle, {}), _jsx(ParallaxProvider, { children: _jsxs(S.Container, { children: [_jsx(Header, {}), _jsxs(Routes, { children: [_jsx(Route, { path: ERoutesLink.HOME, element: _jsx(HomePage, {}) }), _jsx(Route, { path: ERoutesLink.ADVANCED_ENCRYPTION, element: _jsx(AdvancedEncryption, {}) }), _jsx(Route, { path: ERoutesLink.PERSONAL_ACCOUNTS, element: _jsx(PersonalAccounts, {}) }), _jsx(Route, { path: ERoutesLink.BUSINESS_ACCOUNTS, element: _jsx(BusinessAccounts, {}) }), _jsx(Route, { path: ERoutesLink.AI_BOT, element: _jsx(AiBotDetails, {}) }), _jsx(Route, { path: ERoutesLink.ELLO_PAY, element: _jsx(AlloPayPage, {}) }), _jsx(Route, { path: ERoutesLink.FREE_PAY, element: _jsx(FreePaid, {}) }), _jsx(Route, { path: ERoutesLink.GROUPS, element: _jsx(Groups, {}) }), _jsx(Route, { path: "*", element: _jsx(NotFoundPage, {}) })] }), _jsx(Footer, {})] }) })] }));
