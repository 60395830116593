import styled, { keyframes } from 'styled-components';
const blink = keyframes `
  0%, 100% {
    opacity: 1;
    transform: scale(1); /* Нормальний розмір */
  }
  50% {
    opacity: 0.5;
    transform: scale(1.1); /* Трохи збільшений розмір */
  }
`;
const blinkAudio = keyframes `
  0%, 100% {
    opacity: 1;
    transform: scale(1); /* Нормальний розмір */
  }
  50% {
    opacity: 0.5;
    transform: scale(1.1); /* Трохи збільшений розмір */
  }
`;
export const S = {
    ElloPay: styled.div `
  display: flex;
  justify-content: center;
  margin-bottom: 50px;
`,
    ElloPayWrapper: styled.div `
    position: relative;
    display: flex;
    align-content: center;
    width: 100%;
    max-width: 1260px;
    border-radius: 24px;
    padding-top: 67px;
    padding-left: 107px;

    /* Додаємо внутрішній контейнер для управління шириною фону */
    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0; 
      width: 100%; /* Наприклад, фон займає 80% від ширини контейнера */
      height: 100%;
      background: #F5F5F5;
      background-position: center;
      background-size: 100%;
      border-radius: 24px;
    }

    @media (max-width: 1246px) {
      padding-left: 17px;
      margin: 0 5px;
    };

    @media (max-width: 1024px) {
      flex-direction: column;
    };
  `,
    ElloPayContentText: styled.div `
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    max-width: 405px;
    border-radius: 24px;
    background: #F5F5F5;
    margin: 0 auto;

    div {
      div {
        justify-content: flex-start;

        h2 {
          text-align: start;
        }

        p {
          text-align: start;
        }
      }
    }
  `,
    ElloPayContentImages: styled.div `
    position: relative;
    display: flex;
    justify-content: center;
    width: 100%;
    margin: 0 auto;
  `,
    LargeImage: styled.div `
    position: relative;
    z-index: 2;
    width: 100%;
    height: 100%;
    max-width: 319px;
    max-height: 653px;
  `,
    ModernArt: styled.img `
    position: absolute;
    z-index: 3;
    width: 274px;
    bottom: 50px;
    left: 90px;

    @media (max-width: 732px) {
      left: calc(50% - 225px);
    };
  `,
    PayPal: styled.img `
    position: absolute;
    width: 274px;
    top: -31px;
    right: 51px;

    @media (max-width: 732px) {
      right: calc(50% - 225px);
    };
  `,
    Card: styled.img `
    position: absolute;
    z-index: 5;
    width: 438px;
    height: 292px;
    top: 72px;
    right: 156px;

    @media (max-width: 1246px) {
      left: calc(50% - 225px);
      right: auto;
    };

    
    @media (max-width: 480px) {
      min-width: 413px;
      left: calc(50% - 210px);
    };

    @media (max-width: 388px) {
      min-width: 413px;
      left: calc(50% - 216px);
    };
  `,
    Dolar: styled.img `
    position: absolute;
    z-index: 5;
    width: 68px;
    height: 68px;
    bottom: 72px;
    right: 85px;
    animation: ${blink} 1.5s infinite; /* Підключення анімації */
    animation-delay: 0.5s; /* Затримка перед стартом */

    @media (max-width: 732px) {
      right: calc(50% - 225px);
    };
  `,
    Audio: styled.img `
    position: absolute;
    z-index: 5;
    width: 48px;
    height: 48px;
    top: 12px;
    left: 90px;
    animation: ${blinkAudio} 1.8s infinite; /* Підключення анімації */
    
    @media (max-width: 732px) {
      left: calc(50% - 225px);
    };
  `,
};
