import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { S } from './OnlineCoursesStyles';
import { S as Style } from '../PersonalAccounts/PersonalAccountsStyles';
import { TextContainer } from '../TextContainer/TextContainer';
import { ButtonsContainer } from '../ButtonsContainer/ButtonsContainer';
// import { S as StyleImg } from '../ListWrapper/ListWrapperStyles';
import img1 from '../../assets/images/online-curses/1.png';
import img2 from '../../assets/images/online-curses/2.png';
import img3 from '../../assets/images/online-curses/3.png';
import img4 from '../../assets/images/online-curses/4.png';
import img5 from '../../assets/images/online-curses/5.png';
const images = [img1, img2, img3, img4, img5];
const title = 'Creating or enrolling in online courses is fast and easy';
const text = 'If you\'re an expert in your field or a creative enthusiast, you can create professional, engaging courses using the robust and user-friendly interface in just a few clicks.';
const OnlineCourses = () => (_jsxs(S.OnlineCoursesWrapper, { children: [_jsxs(Style.TextWrap, { children: [_jsx(TextContainer, { title: title, paragraph: text }), _jsx(ButtonsContainer, { btnLeftName: "Get App", btnRightName: "Learn More", children: _jsxs("svg", { xmlns: "http://www.w3.org/2000/svg", width: "18", height: "19", viewBox: "0 0 18 19", fill: "none", children: [_jsx("path", { d: "M10.8225 4.94727L15.375 9.49977L10.8225 14.0523", stroke: "#0A49A5", strokeWidth: "1.5", strokeMiterlimit: "10", strokeLinecap: "round", strokeLinejoin: "round" }), _jsx("path", { d: "M2.625 9.5H15.2475", stroke: "#0A49A5", strokeWidth: "1.5", strokeMiterlimit: "10", strokeLinecap: "round", strokeLinejoin: "round" })] }) })] }), _jsx(S.ImagesWrapper, { children: images.map((image) => (_jsx("img", { src: image, alt: "Courses" }, image))) })] }));
export default OnlineCourses;
