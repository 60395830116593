import styled from 'styled-components';
export const S = {
    Button: styled.button `
    display: inline-flex;
    justify-content: center;
    align-items: center;
    padding: 12px 24px;
    font-family: inherit;
    font-size: 16px;
    color: ${(props) => props.theme.colors['--Color-Text-Dark']};
    background-color: ${({ $background }) => $background || 'transparent'};
    border: none;
    border-radius: 4px;
    text-align: center;
    cursor: pointer;
    position: relative;
    overflow: hidden; // Щоб анімація не виходила за межі кнопки
    transition: background-color 0.3s ease;
    ${(props) => props.$selector}

    &:disabled {
      background-color: #ccc;
      cursor: not-allowed;
    }

    .border-svg {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      pointer-events: none;
      overflow: visible; // Щоб уникнути обрізання елемента
    }

    .border-rect {
      stroke-width: 2; // Однакова товщина бордера
      stroke: ${(props) => props.theme.colors[props.$variant]};
      fill: none;
      stroke-dasharray: 60 400; // Видима частина 60 пікселів, прихована частина 400
      stroke-dashoffset: 460; // Початкове значення
    }

    &:hover .border-rect {
      opacity: 0;
    }

    &:hover {
      background-color: ${(props) => props.theme.colors[props.$variant]};
      color: ${(props) => props.theme.colors['--Color-Text-White']};
    }
  `,
};
