import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { S } from './ImageBoxStyles';
import first from '../../../assets/images/first_block/1.png';
import second from '../../../assets/images/first_block/2.png';
import third from '../../../assets/images/first_block/3.png';
import fourth from '../../../assets/images/first_block/4.png';
export const ImageBox = () => {
    const [scrollY, setScrollY] = useState(0);
    // Update scroll position on scroll
    const handleScroll = () => {
        setScrollY(window.scrollY);
    };
    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);
    return (_jsxs(S.ImagesWrap, { children: [_jsx("img", { src: first, alt: "images", style: {
                    transform: `translateY(-${scrollY * 0.6}px)`,
                } }), _jsx("img", { src: second, alt: "images", style: {
                    transform: `translateY(-${scrollY * 0.6}px)`,
                } }), _jsx("img", { src: third, alt: "images", style: {
                    transform: `translateY(-${scrollY * 0.6}px)`,
                } }), _jsx("img", { src: fourth, alt: "images", style: {
                    transform: `translateY(-${scrollY * 0.6}px)`,
                } })] }));
};
