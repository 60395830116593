import { jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useRef, useState } from 'react';
import { S } from './TextBlockStyles';
const text = 'Ello is a secure cloud-based AI messenger with seamless sync, accessing your messages from several devices at once.';
const textGray = 'You can share and store an unlimited number of photos, videos and files.';
export const TextBlock = () => {
    const [isVisible, setIsVisible] = useState(false);
    const [scrollY, setScrollY] = useState(0);
    const textRef = useRef(null);
    const handleScroll = () => {
        setScrollY(window.scrollY);
        const rect = textRef.current?.getBoundingClientRect();
        if (rect && rect.top <= window.innerHeight && rect.bottom >= 0) {
            setIsVisible(true);
        }
        else {
            setIsVisible(false);
        }
    };
    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);
    return (_jsxs(S.TextWrap, { ref: textRef, className: isVisible ? 'visible' : '', style: {
            transform: `translateY(-${scrollY * 0.4}px)`,
        }, children: [text.split(' ').map((word, index) => (_jsxs("span", { className: "black", style: { transitionDelay: `${index * 0.1}s` }, children: [word, "\u00A0"] }, word + String(index)))), textGray.split(' ').map((word, index) => (_jsxs("span", { className: "gray", style: { transitionDelay: `${index * 0.1}s` }, children: [word, "\u00A0"] }, word + String(index))))] }));
};
