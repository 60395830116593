import styled, { keyframes } from 'styled-components';
import BG from '../../assets/images/Ai-bot/BG.jpg';
const shake = keyframes `
  0% { transform: translate(0, 0) rotate(0); }
  10% { transform: translate(-5px, 5px) rotate(-3deg); }
  20% { transform: translate(5px, -5px) rotate(3deg); }
  30% { transform: translate(-5px, -5px) rotate(-3deg); }
  40% { transform: translate(5px, 5px) rotate(3deg); }
  50% { transform: translate(0, 0) rotate(0); }
  60% { transform: translate(-5px, 5px) rotate(-3deg); }
  70% { transform: translate(5px, -5px) rotate(3deg); }
  80% { transform: translate(-5px, -5px) rotate(-3deg); }
  90% { transform: translate(5px, 5px) rotate(3deg); }
  100% { transform: translate(0, 0) rotate(0); }
`;
// Ensure styled component is correctly typed
export const S = {
    AiBotWrapper: styled.div `
    background: url(${BG});
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    padding: 100px 0;

    h2 {
      width: 846px;
      color: ${(props) => props.theme.colors['--Color-Text-Dark']};
      text-align: center;
      font-family: Poppins;
      font-size: 46px;
      font-style: normal;
      font-weight: 500;
      line-height: 120%; /* 55.2px */
      text-align: center;
      margin: 0 auto;

      @media (max-width: 810px) {
        width: 100%;
        max-width: 846px;
        font-size: 26px;
        line-height: 34px; /* 55.2px */
      };
    }
  `,
    AiBotContain: styled.div `
    display: grid;
    grid-template-columns: 0.8fr 1fr 0.8fr;
  `,
    AiBotContainLeft: styled.section `
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 30px;

    @media (max-width: 888px) {
      gap: 10px;
    };
  `,
    AiBotContainCenter: styled.div `
    justify-self: center;
    height: 100%;

    img {
      @media (max-width: 1381px) {
        width: 230px;
      };
    }
  `,
    AiBotContainRight: styled.div `
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 30px;
    justify-self: flex-end;

    @media (max-width: 888px) {
      gap: 10px;
    };

    @media (max-width: 805px) {
      position: relative;
      margin-right: -224px;
    };

    @media (max-width: 534px) {
      margin-right: -341px;
    };
  `,
    AiBotContainImages: styled.div `
    display: flex;
    gap: 30px;
    justify-content: ${(props) => props.$justifyContent || 'flex-start'};

    img {
      &:hover {
        animation: ${shake} 0.5s infinite;
      }

      @media (max-width: 1381px) {
        height: 100px;
      };
    }

    @media (max-width: 888px) {
      gap: 10px;
    };

    @media (max-width: 805px) {
      position: relative;
      left: -100px;
    };

    @media (max-width: 534px) {
      position: relative;
      left: -157px;
    };
  `,
};
