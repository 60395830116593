import styled from 'styled-components';
export const S = {
    Container: styled.div `
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 148px 0;

    @media (max-width: 1024px) {
      padding: 98px 0;
    };

    @media (max-width: 768px) {
      padding: 44px 0;
    };
  `,
};
