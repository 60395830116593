import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { S } from './FirstBlockStyles';
import { Buttons } from './Buttons/Buttons';
import { ImageBox } from './ImageBox/ImageBox';
import { TextBlock } from './TextBlock/TextBlock';
export const FirstBlock = () => {
    const [opacity, setOpacity] = useState(1);
    // Update scroll position on scroll
    const handleScroll = () => {
        if (window.scrollY > 100) {
            setOpacity(0.5);
        }
        if (window.scrollY > 200) {
            setOpacity(0);
        }
        if (window.scrollY < 100) {
            setOpacity(1);
        }
    };
    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);
    return (_jsxs("div", { children: [_jsxs(S.TitleWrap, { style: {
                    opacity,
                    transition: 'opacity 0.7s',
                }, children: [_jsx(S.Title, { children: "Ello. Fast and Secured." }), _jsxs(S.Paragraph, { children: ["Cloud based. End-to-end encrypted.", ' ', _jsx("br", {}), "Globally accessible."] })] }), _jsx("div", { style: {
                    opacity,
                    transition: 'opacity 0.7s',
                }, children: _jsx(Buttons, {}) }), _jsx(ImageBox, {}), _jsx(TextBlock, {})] }));
};
