import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { BurgerMenu } from '../BurgerMenu/BurgerMenu';
import { navList } from '../../CONST/nav-list';
import { S } from './HeaderStyles';
import { Button } from '../Button/Button';
import logo from '../../assets/images/logo.png';
export const Header = () => {
    const [isOpen, setIsOpen] = useState(false);
    const navigate = useNavigate();
    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };
    const handleClick = () => {
        // eslint-disable-next-line no-alert
        alert('Button clicked!');
    };
    const onAnchor = (index) => {
        index < 4 ? `#${navList[index].toLowerCase().replaceAll(' ', '_')}` : `#${navList[index - 1].toLowerCase()}`;
        if (index === 3) {
            return `#${navList[index - 1].toLowerCase().replace(' ', '_')}`;
        }
        return `#${navList[index].toLowerCase().replace(' ', '_')}`;
    };
    const onLink = (e, index) => {
        e.preventDefault();
        const anchor = onAnchor(index);
        // Спочатку переходимо на головну сторінку
        navigate('/', {
            replace: false, // Додаємо маршрут у стек
        });
        // Прокручуємо до потрібного елемента після переходу
        setTimeout(() => {
            const element = document.querySelector(anchor);
            if (element) {
                element.scrollIntoView({ behavior: 'smooth' });
            }
        }, 100); // Затримка для завантаження сторінки
    };
    return (_jsxs(_Fragment, { children: [_jsxs(S.Header, { children: [_jsx(Link, { to: "/", children: _jsx(S.Logo, { alt: "logo", src: logo }) }), _jsxs(S.BurgerButton, { onClick: toggleMenu, "$isOpen": isOpen, children: [_jsx("span", {}), _jsx("span", {}), _jsx("span", {})] }), _jsx(S.Navigation, { children: _jsx(S.List, { children: navList.map((nav, index) => (_jsx(S.Item, { children: _jsx(Link, { onClick: (e) => onLink(e, index), to: onAnchor(index), children: nav }) }, nav))) }) }), _jsxs(S.Buttons, { children: [_jsx(Button, { onClick: handleClick, variant: "--Color-Border-Blue", children: "Contact" }), _jsx(Button, { onClick: handleClick, variant: "--Color-Border-Blue", children: "Get App" })] })] }), _jsx(BurgerMenu, { isOpen: isOpen, toggleMenu: toggleMenu })] }));
};
