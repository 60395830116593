import { jsx as _jsx } from "react/jsx-runtime";
import { hydrateRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { configureStore } from '@reduxjs/toolkit';
import rootReducer from '../shared/store';
import { App } from './App/App';
const store = configureStore({
    reducer: rootReducer,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    preloadedState: window.__INITIAL_STATE__,
});
hydrateRoot(document.getElementById('root'), _jsx(Provider, { store: store, children: _jsx(BrowserRouter, { children: _jsx(App, {}) }) }));
