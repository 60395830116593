import styled from 'styled-components';
export const S = {
    ListWrapper: styled.div `
    position: relative;
    display: grid;
    grid-template-columns: ${(props) => props.$gridTemplateColumns || '1fr 0.4fr'};
    max-width: 1300px;
    width: 100%;
    padding: 0 10px 160px;
    margin: 0 auto;
    /* @media (max-width: 535px) {
      flex-direction: column;
    }; */
  `,
    ImagesWrap: styled.div `
    position: relative;
    display: flex;
    justify-content: ${(props) => props.$justifyContent || 'space-between'};
    align-items: center;
    max-width: 100%; /* Контейнер не виходить за межі батьківського елементу */
    overflow: hidden; /* Забезпечує, щоб зображення не виходили за межі */

    img {
      width: 100%;       /* Зображення заповнює всю ширину контейнера */
      height: auto;      /* Висота змінюється пропорційно */
      object-fit: cover; /* Контролює обрізання та заповнення */
    }

    span {
      display: block;

      &:nth-child(1) {
        position: relative;
        width: 100%;       /* Зображення заповнює всю ширину контейнера */
        max-width: 522px;
        max-height: 632px;
        ${(props) => props.$firstChild}

        img {
          ${(props) => props.$firstChild}
        }

      }

      &:nth-child(2) {
        position: relative;
        width: 50%;       /* Зображення заповнює всю ширину контейнера */
        left: -77px;
        z-index: 2;
        max-width: 267px;
        max-height: 577px;
        ${(props) => props.$secondChild}
        img {
          ${(props) => props.$secondChild}
        }
      }

      &:nth-child(3) {
        position: relative;
        width: 45%;       /* Зображення заповнює всю ширину контейнера */
        left: -100px;
        max-width: 251px;
        max-height: 543px;
        ${(props) => props.$thirdChild}
        img {
          ${(props) => props.$thirdChild}
        }
      }
    }
  `,
    ListInner: styled.div `
    position: relative;
    left: -30px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    padding: 0 20px;
    ${(props) => props.$gridRow
        && Object.entries(props.$gridRow)
            .map(([key, value]) => `${key}: ${value};`)
            .join('')}

    h3 {
      color: ${(props) => props.theme.colors['--Color-Text-Dark']};
      font-family: Poppins;
      font-size: 26px;
      font-style: normal;
      font-weight: 500;
      line-height: 140%; /* 36.4px */
    }

    ul {
      li {
        color: ${(props) => props.theme.colors['--Color-Text-Dark']};
        font-family: "Public Sans";
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 140%; /* 22.4px */
        margin-bottom: 8px;
        position: relative;
        padding-left: 18px; /* Відступ для кружечка */
        margin-bottom: 10px; /* Відступ між пунктами */

        &::before {
          content: ''; /* Додаємо псевдоелемент */
          position: absolute;
          left: 0;
          top: 50%;
          transform: translateY(-50%);
          width: 6px; /* Розмір кружечка */
          height: 6px; /* Розмір кружечка */
          background-color: #9B51E0; /* Колір кружечка */
          border-radius: 50%; /* Робимо його круглим */
        }
      }
    }
  `,
    Line: styled.div `
    display: block;
    margin: 16px 0 24px;
    width: 16px;
    height: 2px;
    background-color: ${(props) => props.theme.colors['--Color-Text-Dark']};
  `,
};
