import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { S } from './ListWrapperStyles';
import List from './List/List';
import { ButtonsContainer } from '../ButtonsContainer/ButtonsContainer';
const ListWrapper = ({ gridTemplateColumns, gridRow, list, titleList, listImg, firstChild, secondChild, thirdChild, link, }) => (_jsxs(S.ListWrapper, { "$gridTemplateColumns": gridTemplateColumns, children: [_jsxs(S.ImagesWrap, { "$firstChild": firstChild, "$secondChild": secondChild, "$thirdChild": thirdChild, children: [_jsx("span", { children: _jsx("img", { 
                        // width={522}
                        // height={632}
                        src: listImg[0], alt: "" }) }), _jsx("span", { children: _jsx("img", { 
                        // width={267}
                        // height={577}
                        src: listImg[1], alt: "" }) }), _jsx("span", { children: _jsx("img", { 
                        // width={251}
                        // height={543}
                        src: listImg[2], alt: "" }) })] }), _jsxs(S.ListInner, { "$gridRow": gridRow, children: [_jsx("h3", { children: titleList }), _jsx(S.Line, {}), _jsx("ul", { children: list.map((item) => (_jsx(List, { item: item }, item))) }), _jsx(ButtonsContainer, { btnRightName: "Learn More", justifyContent: "flex-start", padding: "16px 32px 16px 0", link: link, children: _jsxs("svg", { xmlns: "http://www.w3.org/2000/svg", width: "18", height: "19", viewBox: "0 0 18 19", fill: "none", children: [_jsx("path", { d: "M10.8225 4.94727L15.375 9.49977L10.8225 14.0523", stroke: "#0A49A5", strokeWidth: "1.5", strokeMiterlimit: "10", strokeLinecap: "round", strokeLinejoin: "round" }), _jsx("path", { d: "M2.625 9.5H15.2475", stroke: "#0A49A5", strokeWidth: "1.5", strokeMiterlimit: "10", strokeLinecap: "round", strokeLinejoin: "round" })] }) })] })] }));
export default ListWrapper;
