import styled from 'styled-components';
export const S = {
    FreePaid: styled.section `
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;

    h2 {
      align-self: start;

      color: #070708;
      font-family: Poppins;
      font-size: 32px;
      font-style: normal;
      font-weight: 500;
      line-height: 140%;
      width: 100%;
    }
    /* @media (max-width: 535px) {
      flex-direction: column;
    }; */
  `,
    FirstBlock: styled.div `
    position: relative;
    display: flex;
    justify-content: center;
    width: 100%;
    margin-top: 80px;

    /* @media (max-width: 535px) {
      flex-direction: column;
    }; */
  `,
    FirstBorder: styled.div `
    display: flex;
    align-items: center;
    justify-content: center;
    width: 900px;
    height: 900px;
    flex-shrink: 0;
    border-radius: 900px;
    border: 2px solid rgba(12, 90, 204, 10%);

    /* @media (max-width: 535px) {
      flex-direction: column;
    }; */
  `,
    SecondBorder: styled.div `
    display: flex;
    align-items: center;
    justify-content: center;
    width: 700px;
    height: 700px;
    flex-shrink: 0;
    border-radius: 700px;
    border: 2px solid rgba(12, 90, 204, 50%);
    /* @media (max-width: 535px) {
      flex-direction: column;
    }; */
  `,
    ThreeBorder: styled.div `
    display: flex;
    align-items: center;
    justify-content: center;
    width: 500px;
    height: 500px;
    flex-shrink: 0;
    border-radius: 500px;
    border: 2px solid #0C5ACC;
    /* @media (max-width: 535px) {
      flex-direction: column;
    }; */
  `,
    FirstBlockTtile: styled.div `
    position: absolute;
    z-index: 2;
    bottom: 145px;
    left: 0;
    right: 0;
    background: rgba(255, 255, 255, 0.97);
    padding: 100px 0;

    p {
      color: var(--Color-Text-Dark, #070708);
      font-family: Poppins;
      font-size: 26px;
      font-style: normal;
      font-weight: 400;
      line-height: 140%;
      max-width: 1046px;
      text-align: center;
      margin: 0 auto;

      strong {
        color: var(--Color-Text-Dark, #070708);
        font-weight: 600;
      }
    }
    /* @media (max-width: 535px) {
      flex-direction: column;
    }; */
  `,
    FirstBlockImgLeft: styled.div `
    position: absolute;
    left: calc(50% - 542px);
    top: 125px;
    /* @media (max-width: 535px) {
      flex-direction: column;
    }; */
  `,
    FirstBlockImgCenter: styled.div `
    position: absolute;
    overflow: hidden;
    right: calc(50% - 150px);
    width: 300px;
    top: 0;
    
    img {
      padding: 2px 10px 0 10px;
      object-fit: contain;
      width: 100%;
      border-radius: 41px;
    }
    /* @media (max-width: 535px) {
      flex-direction: column;
    }; */
  `,
    FirstBlockImgRight: styled.div `
    position: absolute;
    left: calc(50% + 305px);
    top: 125px;

    /* @media (max-width: 535px) {
      flex-direction: column;
    }; */
  `,
    Container: styled.div `
    padding: 10px;
    background-image: url(${({ $bg }) => $bg});
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    overflow: hidden;
    /* @media (max-width: 535px) {
      flex-direction: column;
    }; */
  `,
    SecondBlock: styled.div `
    position: relative;
    display: flex;
    justify-content: center;
    margin-top: -145px;
    background: rgba(255, 255, 255, 0.97);
    width: 100%;
  `,
    SecondBlockContent: styled.div `
    display: flex;
    justify-content: space-between;
    width: 100%;
    max-width: 1260px;
    ${({ $reverse }) => $reverse}
  `,
    SecondBlockContentRight: styled.div `
    display: flex;
    align-items: center;

    img {
      margin-right: 41px;

      &:last-child {
        margin-right: 0;
      }
    }
  `,
    SecondBlockContentLeft: styled.div `
    width: auto;
  `,
    GreenText: styled.div `
    color: #44BE2E;
    font-family: "Public Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 140%; /* 22.4px */
    text-transform: uppercase;
    margin-bottom: 8px;
  `,
    TitleList: styled.div `
    position: relative;
    color: var(--Color-Text-Dark, #070708);
    font-family: Poppins;
    font-size: 32px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%; /* 44.8px */

    &::after {
      content: '';
      position: absolute;
      bottom: -20px;
      display: block;
      width: 16px;
      height: 2px;
      background: #070708;
    }
  `,
    SubTitleList: styled.div `
    margin-top: 40px;
    color: #070708;
    font-family: "Public Sans";
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 25.2px */
  `,
    List: styled.ul `
    width: 100%;
    position: relative;
    padding-left: 20px;
    padding-top: 40px;
  `,
    Item: styled.li `
    position: relative;
    width: 100%;
    padding-bottom: 8px;

    &:last-child {
      padding-bottom: 0;
    }

    &::before {
      content: '';
      position: absolute;
      top: 50%;
      left: -17px;
      transform: translateY(-50%);
      width: 6px;
      height: 6px;
      background-color: #F90;
      border-radius: 50%;
    }

    strong {
      color: #070708;
      font-family: "Public Sans";
      font-size: 18px;
      font-style: normal;
      font-weight: 600;
      line-height: 140%; /* 25.2px */
    }
  `,
    KeyFeaturesWrap: styled.div `
    display: flex;
    justify-content: center;
    width: 100%;
  `,
    KeyFeatures: styled.div `
    width: 100%;
    max-width: 1260px;
  `,
    KeyFeaturesList: styled.div `
    display: flex;
    justify-content: space-between;
    width: 100%;
    gap: 15px;
    ${({ $grid }) => $grid}
  `,
    KeyFeaturesItem: styled.div `
    width: 100%;
    max-width: 1260px;
  `,
    Square: styled.div `
    width: 44px;
    height: 44px;
    background-color: #F0F6FF;
    margin-bottom: 28px;
  `,
    KeyFeaturesTitle: styled.div `
    color: #070708;
    font-family: Roboto;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 120%; /* 21.6px */
    margin-bottom: 12px;
  `,
    KeyFeaturesSubTitle: styled.div `
    color: #4D4D50;
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 22.4px */
  `,
    Wrapper: styled.div `
    display: flex;
    justify-content: center;
    padding: 120px 0 180px;
    border-radius: 24px;
    width: 100%;
  `,
    Inner: styled.div `
    overflow: hidden;
    display: flex;
    width: 100%;
    max-width: 1260px;
    background: #F0F6FF;
    border-radius: 24px;
    padding: 77px 0 0 107px;

  `,
    Left: styled.div `
    position: relative;
    z-index: 3;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;

    h3 {
      color: #070708;
      font-family: Poppins;
      font-size: 32px;
      font-style: normal;
      font-weight: 500;
      line-height: 140%; /* 44.8px */
      margin-bottom: 16px;
    }

    p {
      color: #070708;
      font-family: "Public Sans";
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 140%; /* 25.2px */
      margin-bottom: 44px;
    }
  `,
    Right: styled.div `
    display: flex;
    justify-content: center;
    width: 100%;
    /* overflow: hidden; */
    position: relative;
  `,
    ImgWrap: styled.div `
    position: relative;
    width: 307px;
    background: url(${({ $bg }) => $bg});
    padding: 12px 12px 0 12px;
    bottom: 0;
    /* left: calc(50% - 100px); */

    img {
      object-fit: contain;
      width: 100%;
    }
  `,
    ImgWrapSmallOne: styled.div `
    position: absolute;
    z-index: 5;
    ${({ $position }) => $position}

    img {
      object-fit: contain;
      width: 100%;
    }
  `,
    ImgWrapSmallTwo: styled.div `
    position: absolute;
    ${({ $position }) => $position}
    z-index: 5;

    img {
      object-fit: contain;
      width: 100%;
    }
  `,
    CircleOne: styled.div `
    position: absolute;
    bottom: -234px;
    right: -88px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 681px;
    height: 681px;
    border-radius: 681px;
    border: 2px solid rgba(12, 90, 204, 25%);
  `,
    CircleTwo: styled.div `
    display: flex;
    align-items: center;
    justify-content: center;
    width: 529px;
    height: 529px;
    border-radius: 529px;
    border: 2px solid rgba(12, 90, 204, 50%);
  `,
    CircleThree: styled.div `
    width: 378.333px;
    height: 378.333px;
    border-radius: 378.333px;
    border: 2px solid #0C5ACC;
  `,
};
