import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Routes, Route } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import { Header } from '../components/Header/Header';
import { themeDark } from '../assets/styles/ThemeProvider';
import { GlobalStyle } from '../assets/styles/GlobalStyle';
import HomePage from '../pages/HomePage/HomePage';
import AboutPage from '../pages/AboutPage/AboutPage';
import NotFoundPage from '../pages/NotFoundPage/NotFoundPage';
import { S } from './AppStyles';
// import '../assets/styles/style.css';
export const App = () => (_jsxs(ThemeProvider, { theme: themeDark, children: [_jsx(GlobalStyle, {}), _jsxs(S.Container, { children: [_jsx(Header, {}), _jsxs(Routes, { children: [_jsx(Route, { path: "/", element: _jsx(HomePage, {}) }), _jsx(Route, { path: "/about", element: _jsx(AboutPage, {}) }), _jsx(Route, { path: "*", element: _jsx(NotFoundPage, {}) })] })] })] }));
