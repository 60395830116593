import styled from 'styled-components';
export const StyleFinancial = {
    FinancialWrapper: styled.div `
    display: grid;
    grid-template-columns: max-content 1fr max-content;
    align-content: center;
    width: 100%;
    max-width: 1260px;
    border-radius: 24px;
    background: #F5F5F5;
    margin: 0 0 60px;
    padding-top: 67px;
    padding-left: 107px;
    padding-right: 107px;
    overflow: hidden; // Додано
    margin: 0 5px;

    @media (max-width: 1070px) {
      padding-left: 17px;
      padding-right: 17px;
    };

    @media (max-width: 1024px) {
      display: grid;
      grid-template-columns: 1fr 1fr;
    };

    @media (max-width: 588px) {
      display: grid;
      grid-template-columns: max-content 1fr;
    };
  `,
    FinancialTitl: styled.div `
    max-width: 293px;

    @media (max-width: 618px) {
      max-width: 193px;

      h2 {
        font-size: 22px !important;
      }
    };

    @media (max-width: 518px) {
      max-width: 150px;
    };
  `,
    FinancialContentText: styled.div `
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: max-content;
    max-width: 405px;
    border-radius: 24px;
    background: #F5F5F5;
    margin: 0 auto;

    div {
      div {
        justify-content: flex-start;

        h2 {
          text-align: start;
        }

        p {
          text-align: start;
        }
      }
    }
  `,
    FinancialImages: styled.div `
    position: relative;
    display: flex;
    gap: 10px; // Додано для правильного відступу
    right: 120px;

    @media (max-width: 1228px) {
      justify-content: center;
      right: auto;
    };

    @media (max-width: 1024px) {
      grid-column: 1 / -1; // Розтягнутися на дві колонки
      grid-row: 2; // В другий рядок
    };
  `,
    FinancialImagesOne: styled.img `
    max-width: 320px;
    width: 100%;
    height: auto; // Додано, щоб зберігати пропорції
    object-fit: contain;

    @media (max-width: 1228px) {
      max-width: 249px;
    };
  `,
    FinancialImagesTwo: styled.img `
    position: absolute;
    left: 143px;
    top: 68px;
    max-width: 449px;
    width: 100%;
    object-fit: contain;
    overflow: hidden; // Додано для обмеження
    clip-path: inset(0 0 0 0); // Опціонально, для обрізання країв
      @media (max-width: 1228px) {
        max-width: 349px;
        left: auto;
        width: 120%;
      };
    `,
    FinancialImagesThree: styled.img `
    position: relative;
    max-width: 198px;
    width: 100%;
    object-fit: contain;
    align-self: flex-end;
    left: 36px;
    bottom: 76px;

    @media (max-width: 1228px) {
      position: absolute;
      max-width: 130px;
      width: 120%;
      left: calc(50% - 233px);
    };

    @media (max-width: 1070px) {
      max-width: 130px;
      left: calc(50% - 233px);
      bottom: 56px;
    };
  `,
    TextWrapper: styled.div `
    max-width: 251px;
    margin-left: 20px;

    @media (max-width: 1024px) {
      margin-bottom: 20px;
    };
  `,
    TextTitle: styled.div `
    color: #070708;
    font-family: "Public Sans";
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 140%; /* 25.2px */
    margin: 6px 0;

    @media (max-width: 518px) {
      font-size: 14px;
    };
  `,
    TextParagh: styled.div `
    color: #070708;
    font-family: "Public Sans";
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 25.2px */

    @media (max-width: 518px) {
      font-size: 14px;
    };
  `,
};
