import styled from 'styled-components';
export const S = {
    TextContainer: styled.div `
    max-width: 626px;
    margin: 0 auto;

    h2 {
      color: ${(props) => props.theme.colors['--Color-Text-Dark']};
      text-align: center;
      font-family: Poppins;
      font-size: 32px;
      font-style: normal;
      font-weight: 500;
      line-height: 120%; /* 38.4px */
      margin-bottom: 16px;
    }

    p {
      color: ${(props) => props.theme.colors['--Color-Text-Dark']};
      text-align: center;
      font-family: "Public Sans";
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 140%; /* 25.2px */
    }
  `,
};
