import styled, { keyframes } from 'styled-components';
import BG from '../../assets/images/Ai-bot/BG.jpg';
const shake = keyframes `
  0% { transform: translate(0, 0) rotate(0); }
  10% { transform: translate(-5px, 5px) rotate(-3deg); }
  20% { transform: translate(5px, -5px) rotate(3deg); }
  30% { transform: translate(-5px, -5px) rotate(-3deg); }
  40% { transform: translate(5px, 5px) rotate(3deg); }
  50% { transform: translate(0, 0) rotate(0); }
  60% { transform: translate(-5px, 5px) rotate(-3deg); }
  70% { transform: translate(5px, -5px) rotate(3deg); }
  80% { transform: translate(-5px, -5px) rotate(-3deg); }
  90% { transform: translate(5px, 5px) rotate(3deg); }
  100% { transform: translate(0, 0) rotate(0); }
`;
// Ensure styled component is correctly typed
export const S = {
    AiBotWrapper: styled.div `
    background: url(${BG});
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    padding: 100px 0;

    h2 {
      width: 846px;
      color: ${(props) => props.theme.colors['--Color-Text-Dark']};
      text-align: center;
      font-family: Poppins;
      font-size: 46px;
      font-style: normal;
      font-weight: 500;
      line-height: 120%; /* 55.2px */
      text-align: center;
      margin: 0 auto;
    }
  `,
    AiBotContain: styled.div `
    display: grid;
    grid-template-columns: 0.8fr 1fr 0.8fr;
    margin-top: 54px;
  `,
    AiBotContainLeft: styled.div `
    display: flex;
    flex-direction: column;
    gap: 30px;
  `,
    AiBotContainCenter: styled.div `
    justify-self: center;
  `,
    AiBotContainRight: styled.div `
    display: flex;
    flex-direction: column;
    gap: 30px;
    justify-self: flex-end;
  `,
    AiBotContainImages: styled.div `
    display: flex;
    gap: 30px;
    justify-content: ${(props) => props.$justifyContent || 'flex-start'};

    img {
      &:hover {
        animation: ${shake} 0.5s infinite;
      }
    }
  `,
};
