import styled from 'styled-components';
export const S = {
    BurgerWrap: styled.div `
    position: fixed;
    top: 50px; /* Випадає зверху */
    left: 0;
    z-index: 900;
    right: 0;
    height: 100vh;
    transform: translateY(${(props) => (props.$isOpen ? 0 : '-100%')});
    transition: transform 0.5s;
    background-color: ${(props) => props.theme.colors.burgerMenu};
  `,
    BurgerList: styled.ul `
    list-style-type: none;
    padding: 20px;
    margin: 0 auto;
    width: 200px;
  `,
    BurgerItem: styled.li `
    margin: 15px 0;
    width: fit-content;
    position: relative;
    color: ${(props) => props.theme.colors['--dark-blue-gray']};
    text-decoration: none;
    overflow: hidden;
    transition: color 0.3s ease;

    &::before {
      content: '';
      position: absolute;
      width: 0; /* Початкова ширина 0 */
      height: 1px;
      background-color: ${(props) => props.theme.colors['--Color-Border-Blue']};
      bottom: 0;
      left: 50%; /* Початок з центру */
      transform: translateX(-50%);
      transition: width 0.5s ease; /* Анімація зміни ширини */
    }

    &:hover::before {
      width: 100%; /* Розширення лінії в обидва боки */
      left: 50%; /* Залишаємо початок з центру */
      transform: translateX(-50%); /* Зміщення в центр */
    }

    &:hover {
      color: ${(props) => props.theme.colors['--Color-Border-Blue']}; /* Зміна кольору тексту при наведенні */
    }

    &:not(:hover)::before {
      width: 0; /* При втраті фокусу лінія знову стискається до центру */
      left: 50%; /* Залишаємо початок з центру */
      transform: translateX(-50%);
    }

    a {
      text-decoration: none;
      color: #333;
      font-weight: bold;

      &:hover {
        color: ${(props) => props.theme.colors['--Color-Border-Blue']};
      }
    }
  `,
};
