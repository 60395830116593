import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect } from 'react';
import { Parallax, useParallaxController } from 'react-scroll-parallax';
import { useLocation } from 'react-router-dom';
import { Diagram } from '../../components/AdvancedBlock/Diagram/Diagram';
import { TextContainer } from '../../components/TextContainer/TextContainer';
import { CylinderButton } from '../../components/CylinderButton/CylinderButton';
import { S } from './AdvancedEncryptionStyle';
import { Content } from '../../components/Content/Content';
import bg from '../../assets/images/MTProto/bg.png';
import img1 from '../../assets/images/MTProto/1.png';
import img2 from '../../assets/images/MTProto/2.png';
import img3 from '../../assets/images/MTProto/3.png';
import { data } from './data';
const title = 'Advanced Encryption with MTProto 2.0 Technology';
const text = 'In an increasingly interconnected world, data privacy and security are of paramount importance. MTProto 2.0 is a cutting-edge encryption protocol specifically designed to safeguard communications, ensuring that messages remain secure from unauthorized access.';
const title1 = 'What is MTProto 2.0?';
const description = 'MTProto (Mobile Transport Protocol) 2.0 is a sophisticated encryption protocol that enhances secure communication. Developed by Telegram, it builds on the foundations of its predecessor while introducing several improvements in speed, reliability, and security. MTProto 2.0 is tailored for efficient transmission across mobile and unstable networks, making it a powerful solution for modern communication needs.';
const AdvancedEncryption = () => {
    const { pathname } = useLocation();
    const parallaxController = useParallaxController();
    useEffect(() => {
        window.scrollTo(0, 0);
        const timeout = setTimeout(() => {
            if (parallaxController) {
                parallaxController.update();
            }
        }, 100);
        return () => clearTimeout(timeout);
    }, [pathname, parallaxController]);
    return (_jsxs(S.Wrapper, { children: [_jsxs(S.Container, { children: [_jsxs(S.Inner, { children: [_jsxs(CylinderButton, { background: "primary", color: "--Color-Text-White", children: [_jsxs("svg", { xmlns: "http://www.w3.org/2000/svg", width: "18", height: "18", viewBox: "0 0 18 18", fill: "none", children: [_jsx("path", { d: "M12.75 15.375H5.25C3 15.375 1.5 14.25 1.5 11.625V6.375C1.5 3.75 3 2.625 5.25 2.625H12.75C15 2.625 16.5 3.75 16.5 6.375V11.625C16.5 14.25 15 15.375 12.75 15.375Z", stroke: "white", strokeMiterlimit: "10", strokeLinecap: "round", strokeLinejoin: "round" }), _jsx("path", { d: "M12.75 6.75L10.4025 8.625C9.63 9.24 8.3625 9.24 7.59 8.625L5.25 6.75", stroke: "white", strokeMiterlimit: "10", strokeLinecap: "round", strokeLinejoin: "round" })] }), _jsx("span", { children: "Messaging Security" })] }), _jsx(TextContainer, { title: title, paragraph: text })] }), _jsx(Diagram, {}), _jsx(Parallax, { scale: [0.5, 1], opacity: [0, 1], startScroll: 0, endScroll: 700, children: _jsx(Content, { title: title1, desc: description, photo: img1 }) })] }), _jsx(S.BG, { "$bg": bg, children: _jsxs(S.Container, { style: { gap: '44px', width: '100%', justifyContent: 'space-between' }, children: [_jsx(S.H2, { children: "What is MTProto 2.0?" }), _jsx(S.Grid, { children: data.map((item) => (_jsxs("div", { children: [_jsx("h3", { children: item.title }), _jsx("p", { children: item.desc })] }, item.title))) })] }) }), _jsxs(S.Container, { style: { margin: '140px 0 80px' }, children: [_jsx(Parallax, { translateX: [50, -30], children: _jsx(Content, { flexReverse: { flexDirection: 'row-reverse' }, title: title1, desc: description, photo: img2 }) }), _jsx(Parallax, { scale: [-0.5, 1], opacity: [0, 1], startScroll: 0, endScroll: 3500, children: _jsx(Content, { title: title1, desc: description, photo: img3 }) })] })] }));
};
export default AdvancedEncryption;
