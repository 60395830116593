export const data = [
    {
        title: 'End-to-End Encryption:',
        desc: `
      MTProto 2.0 uses end-to-end encryption,
      ensuring that only the intended recipients
      can read the transmitted messages. Even if data is intercepted,
      it remains indecipherable without the proper keys.
    `,
    },
    {
        title: 'Forward Secrecy:',
        desc: `
      The protocol supports forward secrecy,
      meaning even if one session's encryption
      key is compromised, it does not compromise
      the security of past or future sessions.
    `,
    },
    {
        title: 'Layered Security Structure:',
        desc: `
      MTProto 2.0 utilizes a multi-layered encryption
      process that combines symmetric and asymmetric
      cryptography. This combination provides robust
      protection against various types of attacks.
    `,
    },
    {
        title: 'Resilience Against Attacks:',
        desc: `
      MTProto 2.0 incorporates mechanisms to defend against
      man-in-the-middle (MITM) attacks, replay attacks,
      and other common network threats, offering a
      high level of reliability and trustworthiness.
    `,
    },
    {
        title: 'Efficiency and Speed:',
        desc: `
      One of the protocol’s advantages is its ability to
      handle high loads while maintaining low latency,
      making it ideal for real-time messaging on both
      stable and unstable networks.
    `,
    },
];
