import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { ParallaxProvider, Parallax } from 'react-scroll-parallax';
import LatestPosts from '../../components/LatestPosts/LatestPosts';
import PaidChannels from '../../components/PaidChannels/PaidChannels';
import AiBotGenerates from '../../components/AiBotGenerates/AiBotGenerates';
import BusinessAccounts from '../../components/BusinessAccounts/BusinessAccounts';
import PersonalAccounts from '../../components/PersonalAccounts/PersonalAccounts';
import Groups from '../../components/Groups/Groups';
import OnlineCourses from '../../components/OnlineCourses/OnlineCourses';
import ElloPay from '../../components/ElloPay/ElloPay';
import { FirstBlock } from '../../components/FirstBlock/FirstBlock';
import { AdvancedBlock } from '../../components/AdvancedBlock/AdvancedBlock';
import { S } from './HomePageStyles';
const HomePage = () => (_jsxs("div", { style: { overflow: 'hidden' }, children: [_jsx(S.Container, { children: _jsx(FirstBlock, {}) }), _jsxs(ParallaxProvider, { children: [_jsx(Parallax, { children: _jsx(AdvancedBlock, {}) }), _jsx(PersonalAccounts, {}), _jsx(BusinessAccounts, {}), _jsx(AiBotGenerates, {}), _jsx(PaidChannels, {}), _jsx(Groups, {}), _jsx(OnlineCourses, {}), _jsx(LatestPosts, {}), _jsx(ElloPay, {})] })] }));
export default HomePage;
