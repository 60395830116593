import styled from 'styled-components';
export const S = {
    Wrapper: styled.div `
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;
  `,
    Container: styled.div `
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 140px;
    max-width: 1260px;
    width: 100%;
    margin: 0 10px;

    @media (max-width: 657px) {
      gap: 60px;
    };
  `,
    Inner: styled.div `
    margin: 140px 0 0;

    button {
      margin-bottom:32px
    }

    div {
      max-width: 846px;
    }

    h2 {
      font-size: 46px;
    }

    @media (max-width: 657px) {
      margin: 60px 0 0;
    };
  `,
    BG: styled.div `
    display: flex;
    justify-content: center;
    width: 100%;
    height: 100%;
    background: url(${({ $bg }) => $bg});
    background-position: center;
    background-size: cover;
    padding: 140px 10px;
    margin-top: 140px;
  `,
    H2: styled.div `
    color: #070708;
    font-family: "Public Sans";
    font-size: 32px;
    font-style: normal;
    font-weight: 600;
    line-height: 140%; /* 44.8px */
    text-align: start;
    width: 100%;
  `,
    ContentLeft: styled.div `
    width: 100%;
    height: 100%;

    h3 {
      color: var(#070708);
      font-family: "Public Sans";
      font-size: 18px;
      font-style: normal;
      font-weight: 600;
      line-height: 140%;
    }

    p {
      color: var(#070708);
      font-family: "Public Sans";
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 140%; /* 25.2px */
      margin-top: 8px;
    }
  `,
    ContentRight: styled.div `
    width: 100%;
    height: 100%;
  `,
    Grid: styled.div `
    display: grid;
    grid-template-columns: 1fr 1fr;
    justify-content: space-between;
    width: 100%;
    gap: 44px;

    div {
      max-width: 513px;

      h3 {
        color: #070708;
        font-family: "Public Sans";
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: 140%; /* 25.2px */
        margin-bottom: 8px;
      }

      p {
        color: #070708;
        font-family: "Public Sans";
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 140%; /* 25.2px */
      }
    }

    @media (max-width: 768px) {
      grid-template-columns: 1fr;
    };
  `,
};
