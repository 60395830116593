import { Link } from 'react-router-dom';
import styled from 'styled-components';
export const S = {
    footer: styled.div `
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 32px 19px 32px;

    @media (max-width: 921px) {
      flex-direction: column-reverse;
      align-items: center;
    };

    @media (max-width: 565px) {
      padding: 0 8px 19px 8px;
    };
  `,
    footerLeft: styled.div `
    color: #929298;
    font-family: "Public Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

    @media (max-width: 921px) {
      padding-top: 30px;
    };
  `,
    footerRight: styled.div `
    display: block;
  `,
    footerListNav: styled.ul `
    display: flex;
  `,
    footerItem: styled.li `
    padding: 0 24px;
    border-right: 1px solid #070708;

    &:first-child {
      padding-left: 0;
    }

    &:last-child {
      border-right: none;
      padding-right: 0;
    }

    @media (max-width: 673px) {
      padding: 0 14px;
    };

    @media (max-width: 565px) {
      padding: 0 6px;
    };
  `,
    footerLink: styled(Link) `
    color: #070708;
    text-align: center;
    font-family: "Public Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

    @media (max-width: 434px) {
      font-size: 12px;
    };

    @media (max-width: 384px) {
      font-size: 11px;
    };
  `,
};
