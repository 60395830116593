import styled from 'styled-components';
export const S = {
    AdvancedWrap: styled.div `
    max-width: 1300px;
    width: 100%;
    padding: 0 10px 160px;
    margin: 0 auto;
    /* @media (max-width: 535px) {
      flex-direction: column;
    }; */
  `,
    TextWrap: styled.div `
    padding: 24px 0 154px;

    @media (max-width: 1024px) {
      padding: 24px 0 94px;
    };

    @media (max-width: 768px) {
      padding: 24px 0 54px;
    };
  `,
    BusinessWrapper: styled.div `
    span:nth-child(1) {
      @media (max-width: 1024px) {
        left: 129px !important;
      };

      @media (max-width: 745px) {
        left: 75px !important;
      };

      @media (max-width: 468px) {
        left: 22px !important;
      };
    }

    span:nth-child(2) {
      @media (max-width: 1024px) {
        left: 15px !important;
      };

      @media (max-width: 745px) {
        left: -5px !important;
      };

      @media (max-width: 745px) {
        left: -35px !important;
      };
    }

    span:nth-child(3) {
      @media (max-width: 1024px) {
        left: -79px !important;
      };
    }
  `,
};
