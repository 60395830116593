export const listFreeChannelsFirst = [
    `
  <strong>Build Communities:</strong> 
  Connect with people who share your<br/> interests.
  `,
    '<strong>Share Without Limits:</strong> Post updates, photos, videos, and<br/> files effortlessly.',
    '<strong>Collaborate:</strong> Organize group discussions and polls to<br/> engage your audience.',
];
export const listFreeChannelsSecond = [
    'Hobby groups and fan communities',
    'Open educational forums',
    'Local event coordination',
];
export const listPaidChannelsFirst = [
    `
  <strong>Offer Premium Access:</strong> 
  Share exclusive content like<br/> tutorials, behind-the-scenes updates, or premium articles.
  `,
    '<strong>Set Subscription Plans:</strong> Choose flexible pricing models to<br/> match your audience\'s needs.',
    '<strong>Engage Your Subscribers:</strong> Use custom tools to interact<br/> directly with your paying members.',
];
export const listPaidChannelsSecond = [
    'Content creators and influencers',
    'Educators and trainers',
    'Entrepreneurs offering premium services',
];
