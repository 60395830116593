/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { createSlice } from '@reduxjs/toolkit';
import { LoadingStatus } from '../../../client/enum';
const initialState = {
    loadingState: LoadingStatus.NEVER,
    support: null,
    success: '',
    error: '',
    errors: null,
};
const supportSlice = createSlice({
    name: '@@support',
    initialState,
    reducers: {
        resetPeymentsUsState: () => initialState,
        clearErrors: (state) => {
            state.error = '';
            state.errors = null;
        },
        clearSuccess: (state) => {
            state.success = '';
        },
    },
    extraReducers: (builder) => {
        // builder
        //   .addCase(getPeymentLink.pending, (state) => {
        //     state.loadingState = LoadingStatus.LOADING;
        //   })
        //   .addCase(getPeymentLink.fulfilled, (state, { payload }: PayloadAction<IPaymentsResponse>) => {
        //     state.loadingState = LoadingStatus.SUCCESS;
        //   })
        //   .addCase(getPeymentLink.rejected, (state, { payload }: PayloadAction<any>) => {
        //     state.loadingState = LoadingStatus.ERROR;
        //     state.errors = payload;
        //   });
    },
});
export const { resetPeymentsUsState, clearErrors, clearSuccess } = supportSlice.actions;
export default supportSlice.reducer;
