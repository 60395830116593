import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { S } from './FooterStyles';
const navigation = [
    'Privacy Policy',
    'Cookie Policy',
    'Terms',
    'Support',
    'FAQ',
    'Manuals',
];
export const Footer = () => (_jsxs(S.footer, { children: [_jsx(S.footerLeft, { children: "\u00A9 2023 Ello Messenger Corporation" }), _jsx(S.footerRight, { children: _jsx(S.footerListNav, { children: navigation.map((nav) => (_jsx(S.footerItem, { children: _jsx(S.footerLink, { to: "/", children: nav }) }, nav))) }) })] }));
