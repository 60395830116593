import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Parallax } from 'react-scroll-parallax';
import { S } from './PaidChannelsStyles';
import { S as Style } from '../PersonalAccounts/PersonalAccountsStyles';
import { S as StyleImg } from '../ListWrapper/ListWrapperStyles';
import { CylinderButton } from '../CylinderButton/CylinderButton';
import { TextContainer } from '../TextContainer/TextContainer';
import { ButtonsContainer } from '../ButtonsContainer/ButtonsContainer';
import img1 from '../../assets/images/paid-channels/1.png';
import img2 from '../../assets/images/paid-channels/2.png';
import img3 from '../../assets/images/paid-channels/3.png';
const listImg = [img1, img2, img3];
const title = 'Free & paid channels';
const text = 'Media organizations, influencers, artists, and public figures use free and paid channels to stay in touch with their followers, and fans and create new revenue streams.';
const firstChild = {
    width: '100%',
    height: '100%',
    left: '0',
    'max-height': '632px',
    'max-width': '522px',
};
const secondChild = {
    width: '100%',
    'z-index': '5',
    'max-height': '577px',
    'max-width': '267px',
    height: '100%',
    left: '-30px',
};
const thirdChild = {
    width: '100%',
    'max-height': '577px',
    'max-width': '267px',
    left: '0',
};
const PaidChannels = () => (_jsxs(S.Container, { children: [_jsxs(CylinderButton, { background: "--Color-Dark-Green", color: "--Color-Text-White", children: [_jsxs("svg", { xmlns: "http://www.w3.org/2000/svg", width: "18", height: "18", viewBox: "0 0 18 18", fill: "none", children: [_jsxs("g", { clipPath: "url(#clip0_2939_12034)", children: [_jsx("rect", { x: "5.25", y: "8.3999", width: "7.5", height: "1.2", fill: "white" }), _jsx("rect", { x: "5.25", y: "11.5498", width: "7.5", height: "1.2", fill: "white" }), _jsx("rect", { x: "5.25", y: "5.25", width: "7.5", height: "1.2", fill: "white" }), _jsx("path", { d: "M9 17.25C13.5563 17.25 17.25 13.5563 17.25 9C17.25 4.44365 13.5563 0.75 9 0.75C4.44365 0.75 0.75 4.44365 0.75 9C0.75 13.5563 4.44365 17.25 9 17.25Z", stroke: "white", strokeLinecap: "round", strokeLinejoin: "round" })] }), _jsx("defs", { children: _jsx("clipPath", { id: "clip0_2939_12034", children: _jsx("rect", { width: "18", height: "18", fill: "white" }) }) })] }), _jsx("span", { children: "Stay in touch" })] }), _jsxs(Style.TextWrap, { children: [_jsx(TextContainer, { title: title, paragraph: text }), _jsx(ButtonsContainer, { btnLeftName: "Get App", btnRightName: "Learn More", children: _jsxs("svg", { xmlns: "http://www.w3.org/2000/svg", width: "18", height: "19", viewBox: "0 0 18 19", fill: "none", children: [_jsx("path", { d: "M10.8225 4.94727L15.375 9.49977L10.8225 14.0523", stroke: "#0A49A5", strokeWidth: "1.5", strokeMiterlimit: "10", strokeLinecap: "round", strokeLinejoin: "round" }), _jsx("path", { d: "M2.625 9.5H15.2475", stroke: "#0A49A5", strokeWidth: "1.5", strokeMiterlimit: "10", strokeLinecap: "round", strokeLinejoin: "round" })] }) })] }), _jsx(Parallax, { scale: [0.5, 1], children: _jsxs(StyleImg.ImagesWrap, { "$firstChild": firstChild, "$secondChild": secondChild, "$thirdChild": thirdChild, "$justifyContent": "center", children: [_jsx("span", { children: _jsx("img", { 
                            // width={522}
                            // height={632}
                            src: listImg[0], alt: "" }) }), _jsx("span", { children: _jsx("img", { 
                            // width={267}
                            // height={577}
                            src: listImg[1], alt: "" }) }), _jsx("span", { children: _jsx("img", { 
                            // width={251}
                            // height={543}
                            src: listImg[2], alt: "" }) })] }) })] }));
export default PaidChannels;
