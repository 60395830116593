import styled from 'styled-components';
export const S = {
    TitleWrap: styled.div `
    display: flex;
    flex-direction: column;
    gap: 16px;
  `,
    Title: styled.h1 `
    color: ${(props) => props.theme.colors['--Color-Text-Dark']};
    text-align: center;
    font-family: Poppins;
    font-size: 46px;
    font-style: normal;
    font-weight: 500;
    line-height: 120%;
  `,
    Paragraph: styled.p `
    color: ${(props) => props.theme.colors['--Color-Text-Dark']};
    text-align: center;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 25.2px */
  `,
};
