import styled from 'styled-components';
export const S = {
    ElloPay: styled.div `
  display: flex;
  justify-content: center;
`,
    ElloPayWrapper: styled.div `
    display: flex;
    align-content: center;
    width: 100%;
    max-width: 1260px;
    border-radius: 24px;
    background: #F5F5F5;
    margin: 0 0 60px;
    padding-top: 67px;
    padding-left: 107px;
  `,
    ElloPayContentText: styled.div `
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    max-width: 405px;
    border-radius: 24px;
    background: #F5F5F5;
    margin: 0 auto;

    div {
      div {
        justify-content: flex-start;

        h2 {
          text-align: start;
        }

        p {
          text-align: start;
        }
      }
    }
  `,
    ElloPayContentImages: styled.div `
    position: relative;
    display: flex;
    justify-content: center;
    width: 100%;
    margin: 0 auto;
  `,
    LargeImage: styled.div `
    position: relative;
    z-index: 2;
    width: 100%;
    height: 100%;
    max-width: 319px;
    max-height: 653px;
  `,
    ModernArt: styled.img `
    position: absolute;
    z-index: 3;
    width: 274px;
    bottom: 50px;
    left: 90px;
  `,
    PayPal: styled.img `
    position: absolute;
    width: 274px;
    top: -31px;
    right: 51px;
  `,
    Card: styled.img `
    position: absolute;
    z-index: 5;
    width: 438px;
    height: 292px;
    top: 72px;
    right: 156px;
  `,
    Dolar: styled.img `
    position: absolute;
    z-index: 5;
    width: 68px;
    height: 68px;
    bottom: 72px;
    right: 85px;
  `,
    Audio: styled.img `
    position: absolute;
    z-index: 5;
    width: 48px;
    height: 48px;
    top: 12px;
    left: 90px;
  `,
};
