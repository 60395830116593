import styled from 'styled-components';
import BG from '../../assets/images/online-curses/BG.png';
export const S = {
    OnlineCoursesWrapper: styled.div `
    overflow: hidden;
    background: url(${BG});
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    padding: 100px 0 0;
  `,
    ImagesWrapper: styled.div `
    display: flex;
    justify-content: space-around;
    gap: 10px;
    align-items: flex-end;

    img {
      object-fit: scale-down;
      width: 100%;
    }

    @media (max-width: 1270px) {
      height: 208px;

      img {
        object-fit: scale-down;
        max-width: 148px;
      }
    };

    @media (max-width: 768px) {
      height: 148px;

      img {
        object-fit: scale-down;
        max-width: 108px;
      }
    };

    @media (max-width: 564px) {
      height: 108px;

      img {
        object-fit: scale-down;
        max-width: 68px;
      }
    };
  `,
};
