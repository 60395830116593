import styled from 'styled-components';
export const S = {
    ButtonsWrapp: styled.section `
    display: flex;
    justify-content: flex-start;
    gap: 8px;

    button {
      border-radius: 8px;
      background: #2F2F2F;
      padding: 8px 32px;
      border: none;
    }
  `,
};
