import img1 from '../../assets/images/Business-Accounts-details/2.png';
export const data = [
    {
        images: [img1],
        title: 'Business username',
        desc: 'Enjoy consistent access to your account across all your devices—whether it’s your smartphone, tablet, or desktop. Your data syncs automatically, allowing you to switch between devices without missing a beat.',
    },
    {
        images: [img1],
        title: 'Accessible on multiple devices',
        desc: 'Worried about privacy? You can create and manage your personal account without needing to link a phone number, giving you more control over your information and how you share it.',
    },
    {
        images: [img1],
        title: 'Collaborate with your team',
        desc: 'Manage multiple accounts with ease. Whether you’re juggling work and personal profiles or switching between different user identities, you can do so seamlessly without logging in and out.',
    },
    {
        images: [img1],
        title: 'Switch between accounts',
        desc: 'Stay informed and entertained with a mix of free and premium channels. Explore content that suits your interests, with options for both free access and subscription-based services for enhanced features.',
    },
    {
        images: [img1],
        title: 'Free and paid channels',
        desc: 'Create or join groups where you can interact with like-minded people, share interests, and plan activities. Whether for socializing, collaborating, or organizing events, groups help keep everyone in sync.',
    },
    {
        images: [img1],
        title: 'Groups to interact with customers',
        desc: 'Share your real-time location with friends or family members for coordinated meetups, added safety, or just staying in touch. You have full control over when and with whom you share your location.',
    },
    {
        images: [img1],
        title: 'Showcase, promote and sell your products',
        desc: 'Share your real-time location with friends or family members for coordinated meetups, added safety, or just staying in touch. You have full control over when and with whom you share your location.',
    },
];
