import styled from 'styled-components';
export const S = {
    ButtonsWrap: styled.div `
    display: flex;
    justify-content: center;
    gap: 8px;
    margin-top: 44px;
    padding: 0 10px;

    @media (max-width: 535px) {
      flex-direction: column;
    };
  `,
    Padding: {
        padding: '8px 32px',
    },
};
