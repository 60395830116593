export const navList = [
    'Personal',
    'Business',
    'AI Text',
    'AI Image',
    'Channels',
    'Groups',
    'Online Courses',
    'Feed',
    'Analytics',
    'Financials',
];
