import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect } from 'react';
import { navList } from '../../CONST/nav-list';
import { S } from './BurgerMenuStyles';
export const BurgerMenu = ({ isOpen }) => {
    const handleClick = () => { };
    useEffect(() => {
        if (isOpen) {
            document.body.style.overflow = 'hidden';
        }
        else {
            document.body.style.overflow = '';
        }
        return () => {
            document.body.style.overflow = '';
        };
    }, [isOpen]);
    return (_jsx(S.BurgerWrap, { "$isOpen": isOpen, children: _jsx(S.BurgerList, { onCanPlay: handleClick, children: navList.map((link) => (_jsx(S.BurgerItem, { children: _jsx("a", { href: "http://", target: "_blank", rel: "noopener noreferrer", children: link }) }, link))) }) }));
};
