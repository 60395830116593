import styled from 'styled-components';
export const S = {
    TextWrap: styled.div `
    width: 100%;
    max-width: 846px;
    margin: 0 auto;
    padding: 0 15px;

    .black {
      display: inline-block;
      opacity: 0;
      transition: opacity 0.5s ease-in-out;

      color: ${(props) => props.theme.colors['--Color-Text-Dark']};
      font-size: 36px;
      font-style: normal;
      font-weight: 600;
      line-height: 140%; /* 50.4px */
      margin-right: 2px;

      @media (max-width: 1024px) {
        font-size: 24px;
        line-height: 36px;
        text-align: center;
      }
    }
    &.visible span {
      opacity: 1;
    }
    .gray {
      display: inline-block;
      opacity: 0;
      transition: opacity 0.5s ease-in-out;
      color: ${(props) => props.theme.colors['--dark-blue-gray']};
      font-size: 36px;
      font-style: normal;
      font-weight: 400;
      line-height: 140%;

      @media (max-width: 1024px) {
        font-size: 24px;
        line-height: 36px;
        text-align: center;
      }
    }
  `,
};
