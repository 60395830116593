import styled from 'styled-components';
export const S = {
    AdvancedWrap: styled.div `
    max-width: 1300px;
    width: 100%;
    padding: 0 10px 160px;
    margin: 0 auto;
    /* @media (max-width: 535px) {
      flex-direction: column;
    }; */
  `,
    TextWrap: styled.div `
    padding: 24px 0 154px;
  `,
};
