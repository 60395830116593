import styled, { keyframes } from 'styled-components';
const rotate = keyframes `
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;
export const S = {
    LatestPostsWrapper: styled.div `
    position: relative;
    left: -100px;
    display: flex;
    align-items: center;
    gap: 20px;
    justify-content: center;
    padding: 140px 0;
  `,
    LatestPostsTextWrapper: styled.div `
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    max-width: 513px;
    
    button {
      margin: 0;
    }

    div {
      padding: 0;
      div {
        h2 {
          text-align: start;
          padding-top: 15px;
        }
        p {
          text-align: start;
        }
      }
    }
  `,
    LatestImagesWrapper: styled.div `
    position: relative;
    top: -15px;
    display: flex;
    border-radius: 50%;
    gap: 15px;
    width: 508px;
    height: 508px;
    border: 2px solid ${(props) => props.theme.colors['--Color-Border-Blue-2']};
     img {
      position: relative;
      bottom: 35px;
      left: 130px;
      height: 115%;
     }
  `,
    RotatingElement: styled.div `
    position: absolute;
    top: 0;
    left: 0;
    width: 100%; /* Збігається з розміром контейнера */
    height: 100%;
    animation: ${rotate} 10s linear infinite;
    transform-origin: center; /* Обертання навколо центру зовнішнього елемента */
    
    img {
      width: 24px;
      height: 24px;
      position: absolute;
      top: -12px;
      left: 50%;
      transform: translate(-50%, 0) rotate(0deg); /* Фіксує орієнтацію зображення */
      transform-origin: center;
      animation: ${rotate} 10s linear infinite reverse; /* Компенсує обертання */
    }
  `,
};
