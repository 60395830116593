import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Parallax } from 'react-scroll-parallax';
import { ERoutesLink } from '../../CONST/routes-link';
import { S } from './AiBotGeneratesStyles';
import leftImg1 from '../../assets/images/Ai-bot/left-img/1.png';
import leftImg2 from '../../assets/images/Ai-bot/left-img/2.png';
import leftImg3 from '../../assets/images/Ai-bot/left-img/3.png';
import leftImg4 from '../../assets/images/Ai-bot/left-img/4.png';
import leftImg5 from '../../assets/images/Ai-bot/left-img/5.png';
import leftImg6 from '../../assets/images/Ai-bot/left-img/6.png';
import leftImg7 from '../../assets/images/Ai-bot/left-img/7.png';
import iPhone from '../../assets/images/Ai-bot/iPhone.png';
import rightImg1 from '../../assets/images/Ai-bot/right/1.png';
import rightImg2 from '../../assets/images/Ai-bot/right/2.png';
import rightImg3 from '../../assets/images/Ai-bot/right/3.png';
import rightImg4 from '../../assets/images/Ai-bot/right/4.png';
import rightImg5 from '../../assets/images/Ai-bot/right/5.png';
import rightImg6 from '../../assets/images/Ai-bot/right/6.png';
import rightImg7 from '../../assets/images/Ai-bot/right/7.png';
import rightImg8 from '../../assets/images/Ai-bot/right/8.png';
import rightImg9 from '../../assets/images/Ai-bot/right/9.png';
import { ButtonsContainer } from '../ButtonsContainer/ButtonsContainer';
// import { navList } from '../../CONST/nav-list';
const img1 = [leftImg1, leftImg2];
const img2 = [leftImg3, leftImg4, leftImg5];
const img3 = [leftImg6, leftImg7];
const img4 = [rightImg1, rightImg2, rightImg3];
const img5 = [rightImg4, rightImg5, rightImg6];
const img6 = [rightImg7, rightImg8, rightImg9];
const justifyContent = 'flex-end';
const AiBotGenerates = () => (_jsxs(S.AiBotWrapper, { children: [_jsx("h2", { children: "Built-in AI bot generates high-quality text or image results" }), _jsx(ButtonsContainer, { btnRightName: "Learn More", link: ERoutesLink.AI_BOT, children: _jsxs("svg", { xmlns: "http://www.w3.org/2000/svg", width: "18", height: "19", viewBox: "0 0 18 19", fill: "none", children: [_jsx("path", { d: "M10.8225 4.94727L15.375 9.49977L10.8225 14.0523", stroke: "#0A49A5", strokeWidth: "1.5", strokeMiterlimit: "10", strokeLinecap: "round", strokeLinejoin: "round" }), _jsx("path", { d: "M2.625 9.5H15.2475", stroke: "#0A49A5", strokeWidth: "1.5", strokeMiterlimit: "10", strokeLinecap: "round", strokeLinejoin: "round" })] }) }), _jsxs(S.AiBotContain, { children: [_jsxs(S.AiBotContainLeft, { children: [_jsx(S.AiBotContainImages, { children: img1.map((img, index) => (_jsx("img", { style: index === 0 ? { marginLeft: '10px' } : {}, src: img, alt: "/" }, img))) }), _jsx(S.AiBotContainImages, { children: img2.map((img) => (_jsx("img", { src: img, alt: "/" }, img))) }), _jsx(S.AiBotContainImages, { children: img3.map((img, index) => (_jsx("img", { style: index === 0 ? { marginLeft: '10px' } : {}, src: img, alt: "/" }, img))) })] }), _jsx(Parallax, { scale: [0.5, 1], children: _jsx(S.AiBotContainCenter, { children: _jsx("img", { style: { marginTop: '34px' }, src: iPhone, alt: "iPhone" }) }) }), _jsxs(S.AiBotContainRight, { children: [_jsx(S.AiBotContainImages, { "$justifyContent": justifyContent, children: img4.map((img) => (_jsx("img", { src: img, alt: "/" }, img))) }), _jsx(S.AiBotContainImages, { "$justifyContent": justifyContent, children: img5.map((img) => (_jsx("img", { className: "dribble", src: img, alt: "/" }, img))) }), _jsx(S.AiBotContainImages, { "$justifyContent": justifyContent, children: img6.map((img) => (_jsx("img", { className: "dribble", src: img, alt: "/" }, img))) })] })] })] }));
export default AiBotGenerates;
