import styled from 'styled-components';
export const S = {
    Accounts: styled.div `
    display: flex;
    width: 100%;
    justify-content: space-between;
    gap: 30px;

    @media (max-width: 659px) {
      flex-direction: column;
    };
  `,
    AccountsLeft: styled.div `
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 513px;

    h3 {
      color: #070708;
      font-family: Poppins;
      font-size: 26px;
      font-style: normal;
      font-weight: 500;
      line-height: 36px;
      margin-bottom: 19px;
    }

    p {
      color: #070708;
      font-family: "Public Sans";
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 140%; /* 25.2px */
    }

    @media (max-width: 945px) {
      max-width: 313px;
    };

    @media (max-width: 659px) {
      max-width: 100%;
    };
  `,
    AccountsRight: styled.div `
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 30px;
    max-width: 620px;
    width: 100%;
    border-radius: 24px;
    background: #F5F5F5;
    padding: 53px;

    div {
      img {
        max-height: 383px;
        object-fit: contain;
      }
    }

    @media (max-width: 659px) {
      max-width: 100%;
    };
  `,
};
