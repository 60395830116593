import styled from 'styled-components';
export const StyleAI = {
    AiTextWrap: styled.div `
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    /* @media (max-width: 535px) {
      flex-direction: column;
    }; */

    h2 {
      color: #070708;
      text-align: center;
      font-family: Poppins;
      font-size: 46px;
      font-style: normal;
      font-weight: 500;
      line-height: 140%; /* 64.4px */
      max-width: 834px;
      margin: 24px 0;

      @media (max-width: 1498px) {
        font-size: 36px;
        max-width: 534px;
      };

      @media (max-width: 815px) {
        font-size: 26px;
      };

      @media (max-width: 543px) {
        font-size: 16px;
      };
    }

    p {
      color: #070708;
      text-align: center;
      font-family: "Public Sans";
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 140%; /* 25.2px */
      max-width: 834px;

      @media (max-width: 1498px) {
        font-size: 16px;
        max-width: 534px;
      };

      @media (max-width: 815px) {
        font-size: 13px;
      };
    }
  `,
    AiBotContainLeftWrap: styled.div `
    position: relative;
    margin-left: -124px;
    @media (max-width: 805px) {
      margin-left: -14px;
    };
  `,
    AiBotContainRightWrap: styled.div `
    position: relative;
    margin-right: -215px;
    @media (max-width: 805px) {
      margin-right: -39px;
    };
  `,
};
