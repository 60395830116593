import styled from 'styled-components';
export const S = {
    Content: styled.div `
    display: flex;
    justify-content: space-between;
    gap: 30px;
    width: 100%;
    padding: 0 10px;
    ${(props) => props.$flexReverse};

    @media (max-width: 657px) {
      flex-direction: column;
    };
  `,
    Text: styled.div `
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 513px;

    h2 {
      color: var(#070708);
      font-family: "Public Sans";
      font-size: 32px;
      font-style: normal;
      font-weight: 600;
      line-height: 140%; /* 44.8px */
      margin-bottom: 16px;

      
      @media (max-width: 835px) {
        font-size: 28px;
      };
    }

    p {
      color: var(#070708);
      font-family: "Public Sans";
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 140%; /* 25.2px */

        
      @media (max-width: 835px) {
        font-size: 16px;
      };
    }

    @media (max-width: 835px) {
      max-width: 313px;
    };

    @media (max-width: 657px) {
      max-width: 100%;
    };
  `,
    Photo: styled.div `
    max-width: 620px;
    max-height: 620px;

    img {
      object-fit: contain
    }
  `,
};
