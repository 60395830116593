import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useState } from 'react';
import { BurgerMenu } from '../BurgerMenu/BurgerMenu';
import { navList } from '../../CONST/nav-list';
import { S } from './HeaderStyles';
import { Button } from '../Button/Button';
import logo from '../../assets/images/logo.png';
export const Header = () => {
    const [isOpen, setIsOpen] = useState(false);
    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };
    const handleClick = () => {
        // eslint-disable-next-line no-alert
        alert('Button clicked!');
    };
    return (_jsxs(_Fragment, { children: [_jsxs(S.Header, { children: [_jsx(S.Logo, { alt: "logo", src: logo }), _jsxs(S.BurgerButton, { onClick: toggleMenu, "$isOpen": isOpen, children: [_jsx("span", {}), _jsx("span", {}), _jsx("span", {})] }), _jsx(S.Navigation, { children: _jsx(S.List, { children: navList.map((nav) => (_jsx(S.Item, { children: _jsx("a", { href: "http://", target: "_blank", rel: "noopener noreferrer", children: nav }) }, nav))) }) }), _jsxs(S.Buttons, { children: [_jsx(Button, { onClick: handleClick, variant: "--Color-Border-Blue", children: "Contact" }), _jsx(Button, { onClick: handleClick, variant: "--Color-Border-Blue", children: "Get App" })] })] }), _jsx(BurgerMenu, { isOpen: isOpen })] }));
};
