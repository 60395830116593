import styled from 'styled-components';
import BG from '../../assets/images/online-curses/BG.png';
export const S = {
    OnlineCoursesWrapper: styled.div `
    background: url(${BG});
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    padding: 100px 0 0;
  `,
    ImagesWrapper: styled.div `
    display: flex;
    justify-content: space-around;
    gap: 10px;
    align-items: flex-end;
  `,
};
