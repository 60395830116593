import { Link } from 'react-router-dom';
import styled, { keyframes } from 'styled-components';
const arrowAnimation = keyframes `
  0% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(5px); /* Рух вправо на 5px */
  }
  100% {
    transform: translateX(0); /* Повернення в початкове положення */
  }
`;
export const S = {
    ButtonContainer: styled.div `
    display: flex;
    align-items: center;
    justify-content: ${(props) => props.$justifyContent || 'center'};
    gap: 10px;
    width: 100%;
    margin-top: 44px;
  `,
    ButtonLeft: styled.button `
    padding: 16px 32px;
    text-align: center;
    font-family: "Public Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    color: ${(props) => (props.color ? props.color : props.theme.colors['--Color-Text-White'])};
    background-color: ${(props) => (props.background ? props.background : props.theme.colors['--Dark-Charcoal'])};
    border: 2px solid ${(props) => (props.border ? props.border : props.theme.colors['--Dark-Charcoal'])};
    border-radius: 8px;
    cursor: pointer;
    transition: background-color 0.3s ease, color 0.3s ease;

    &:hover {
      color: ${(props) => (props.color ? props.color : props.theme.colors['--Color-Text-White'])};
      background-color: ${(props) => (props.background ? props.background : props.theme.colors['--Dark-Charcoal'])};
      border: 2px solid ${(props) => (props.border ? props.border : props.theme.colors['--Dark-Charcoal'])};
    }

    &:disabled {
      background-color: #ccc;
      color: #666;
      cursor: not-allowed;
      border-color: #ccc;
    }
  `,
    ButtonRight: styled(Link) `
    display: flex;
    align-items: center;
    gap: 10px;
    padding: ${(props) => props.$padding || '16px 32px'};
    text-align: center;
    font-family: "Public Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    color: ${(props) => (props.color ? props.color : props.theme.colors['--Color-Border-Blue'])};
    background-color: ${(props) => (props.background ? props.background : props.theme.colors.transparent)};
    border: 2px solid ${(props) => (props.border ? props.border : props.theme.colors.transparent)};
    border-radius: 8px;
    cursor: pointer;
    transition: background-color 0.3s ease, color 0.3s ease;

    .arrow {
      display: flex;
      animation: ${arrowAnimation} 1s ease-in-out infinite; /* Додаємо анімацію */
    }
  `,
};
