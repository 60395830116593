import styled from 'styled-components';
const Container = styled.div `
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 100%;
`;
export const S = {
    Container,
};
