import styled from 'styled-components';
import BG from '../../assets/images/BG.png';
export const S = {
    Container: styled.div `
    width: 100%;
    min-height: calc(100% - 63px);
    background: url(${BG});
    background-position-x: center;
    background-position-y: top;
    background-size: cover;
    background-repeat: no-repeat;
    padding: 60px 0;
  `,
};
