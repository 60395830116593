import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { S } from '../../../pages/FreePaid/FreePaidStyle';
import { Buttons } from '../../../components/Buttons/Buttons';
import phone from '../../../assets/images/free&chanel/pice.png';
export const WhyChooseElloChannelsBlock = ({ title, paragraphOne, paragraphTwo, imgOne, imgTwo, mainImg, }) => (_jsx(S.Wrapper, { children: _jsxs(S.Inner, { children: [_jsxs(S.Left, { children: [_jsx("h3", { children: title }), _jsx("p", { children: paragraphOne }), _jsx("p", { children: paragraphTwo }), _jsx(Buttons, {})] }), _jsxs(S.Right, { children: [_jsx(S.ImgWrapSmallOne, { "$position": {
                            top: '185px',
                            left: '17px',
                        }, children: _jsx("img", { src: imgOne, alt: "" }) }), _jsx(S.ImgWrapSmallTwo, { "$position": {
                            bottom: '51px',
                            right: '22px',
                        }, children: _jsx("img", { src: imgTwo, alt: "" }) }), _jsx(S.CircleOne, { children: _jsx(S.CircleTwo, { children: _jsx(S.CircleThree, {}) }) }), _jsx(S.ImgWrap, { "$bg": phone, children: _jsx("img", { src: mainImg, alt: "phone" }) })] })] }) }));
