import { createSlice } from '@reduxjs/toolkit';
const initialState = {
    lang: 'en',
    isBurger: false,
    isFirstBurger: false,
};
const appSlice = createSlice({
    name: '@@app',
    initialState,
    reducers: {
        changeAppLanguage: (state, { payload }) => {
            state.lang = payload;
        },
        setBurger: (state, { payload }) => {
            state.isBurger = payload;
        },
        setFirstBurger: (state, { payload }) => {
            state.isFirstBurger = payload;
        },
    },
});
export default appSlice.reducer;
export const { changeAppLanguage, setBurger, setFirstBurger } = appSlice.actions;
