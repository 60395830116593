import styled from 'styled-components';
export const S = {
    ContentBox: styled.div `
    position: relative;
    display: flex;
    align-content: center;
    width: 100%;
    max-width: 1260px;
    border-radius: 24px;
    padding-top: 67px;
    padding-left: 107px;
    overflow: hidden;

    @media (max-width: 1024px) {
      flex-direction: column;
      align-items: center;
      padding: 0 20px;
    };
  `,
};
