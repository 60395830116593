import styled from 'styled-components';
export const S = {
    Image: styled.div `
    position: absolute;
    top: -90px;
    left: calc(50% - 121px);
  `,
    H2: styled.h2 `
    color: #070708;
    text-align: center;
    font-family: "Public Sans";
    font-size: 32px;
    font-style: normal;
    font-weight: 600;
    line-height: 45%;
    margin: 225px 0 80px;
  `,
    Wrap: styled.div `
    padding: 40px 40px 60px;
    border-radius: 24px;
    background: #F5F5F5;
  `,
};
