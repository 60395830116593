import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { S } from '../ElloPay/ElloPayStyles';
import { StyleFinancial } from './FinancialStyles';
import { S as Style } from '../PersonalAccounts/PersonalAccountsStyles';
import { TextContainer } from '../TextContainer/TextContainer';
import { ButtonsContainer } from '../ButtonsContainer/ButtonsContainer';
import one from '../../assets/images/Financial/1.png';
import two from '../../assets/images/Financial/2.png';
import three from '../../assets/images/Financial/3.png';
import { navList } from '../../CONST/nav-list';
const title = 'Financial transactionsin one place';
export const Financial = () => (_jsx(S.ElloPay, { id: navList[9].toLowerCase(), children: _jsxs(StyleFinancial.FinancialWrapper, { children: [_jsx(StyleFinancial.FinancialContentText, { children: _jsxs(Style.TextWrap, { children: [_jsx(StyleFinancial.FinancialTitl, { children: _jsx(TextContainer, { title: title }) }), _jsx(ButtonsContainer, { btnRightName: "Learn More", padding: "16px 32px 16px 0", children: _jsxs("svg", { xmlns: "http://www.w3.org/2000/svg", width: "18", height: "19", viewBox: "0 0 18 19", fill: "none", children: [_jsx("path", { d: "M10.8225 4.94727L15.375 9.49977L10.8225 14.0523", stroke: "#0A49A5", strokeWidth: "1.5", strokeMiterlimit: "10", strokeLinecap: "round", strokeLinejoin: "round" }), _jsx("path", { d: "M2.625 9.5H15.2475", stroke: "#0A49A5", strokeWidth: "1.5", strokeMiterlimit: "10", strokeLinecap: "round", strokeLinejoin: "round" })] }) })] }) }), _jsxs(StyleFinancial.FinancialImages, { children: [_jsx(StyleFinancial.FinancialImagesThree, { src: three, alt: "Modern" }), _jsx(StyleFinancial.FinancialImagesOne, { src: one, alt: "Modern" }), _jsx(StyleFinancial.FinancialImagesTwo, { src: two, alt: "Modern" })] }), _jsxs(StyleFinancial.TextWrapper, { children: [_jsx(StyleFinancial.TextTitle, { children: "Overview:" }), _jsx(StyleFinancial.TextParagh, { children: "Instantly check both your overall earnings and earning trends." }), _jsx(StyleFinancial.TextTitle, { children: "Revenue Breakdown:" }), _jsx(StyleFinancial.TextParagh, { children: "Keep track of your online courses, paid channel, media sales, and other transactions." }), _jsx(StyleFinancial.TextTitle, { children: "Detailed Statistics: " }), _jsx(StyleFinancial.TextParagh, { children: "View the detail performances and research your earnings and withdrawals." })] })] }) }));
