import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useRef } from 'react';
import { S } from './SylesButton'; // Стилізовані компоненти
// Ваш компонент кнопки
export const Button = ({ children, onClick, className = '', disabled = false, type = 'button', variant = 'primary', }) => {
    const buttonRef = useRef(null); // Реф на кнопку
    const borderRef = useRef(null); // Реф на бордер
    useEffect(() => {
        if (borderRef.current) {
            let start = null;
            const duration = 4000; // Тривалість анімації в мілісекундах (4 секунди)
            const animateBorder = (timestamp) => {
                if (!start)
                    start = timestamp;
                const progress = (timestamp - start) / duration;
                const percent = progress % 1; // Прогрес від 0 до 1
                // Обчислюємо поточну позицію бордера
                const offset = 460 - percent * 460; // Починаємо від 460 і поступово до 0
                if (borderRef.current) {
                    borderRef.current.style.strokeDashoffset = `${offset}`;
                }
                requestAnimationFrame(animateBorder); // Продовжуємо анімацію
            };
            requestAnimationFrame(animateBorder);
        }
    }, []);
    return (_jsxs(S.Button, { ref: buttonRef, type: type, onClick: onClick, "$selector": className, disabled: disabled, "$variant": variant, "$background": variant, children: [_jsx("svg", { className: "border-svg", width: "100%", height: "100%", children: _jsx("rect", { ref: borderRef, className: "border-rect", x: "0", y: "0", width: "100%", height: "100%", rx: "4", ry: "4", strokeWidth: "2", stroke: "blue", fill: "none", strokeDasharray: "60 400" // 60 пікселів видимої частини, 400 - прихована частина
                    , strokeDashoffset: "460" }) }), children] }));
};
